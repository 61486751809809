import {Component, Input, OnInit} from '@angular/core';
import {AttachmentsServiceService} from "../../../services/attachments/attachments-service.service";

@Component({
  selector: 'app-files-preview',
  templateUrl: './files-preview.component.html',
  styleUrls: ['./files-preview.component.scss']
})
export class FilesPreviewComponent implements OnInit {
  @Input() filesData = [] as any;

  constructor(
    public attachmentsServiceService: AttachmentsServiceService
  ) { }

  ngOnInit(): void {
    console.log('filesData', this.filesData);
    // attach_files
  }

  isImage(file_type: any): boolean {
    return  this.attachmentsServiceService.isImage(file_type);
  }
}
