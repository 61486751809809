import {environment} from "../../../../environments/environment";

function createUrl(actionName: string): string {
  return `${environment.apiBaseUrl}${environment.apiUrl}${environment.apiVersion}${actionName}`;
}

export const ProductApiResources = {
  list:  createUrl('products'),
  search:  createUrl('products/search'),
  create:  createUrl('products/create'),
  detail:  createUrl('products'),
  update:  createUrl('products'),
  updatePrice:  createUrl('products/update/price'),
  delete:  createUrl('products'),
  discontinue:  createUrl('products/discontinue'),
  types:  createUrl('products/types'),
  changeStatus:  createUrl('products/change/status'),
  manufacturers:  createUrl('manufacturers'),
  AddManufacturer:  createUrl('manufacturers/create'),
  changeAttribute:  createUrl('products/change/attribute'),
  addVendor:  createUrl('products/add/vendor'),
  setDefaultVendor:  createUrl('products/vendor/default'),
  productVendorAsDelete:  createUrl('products/vendor/delete'),
  removeProductVendor:  createUrl('products/remove/vendor'),
  statusList:  createUrl('products/status'),
  getSpecificationFiles:  createUrl('specification-file'),
  createSpecificationFiles:  createUrl('specification-file/create'),

};
