import {environment} from "../../../environments/environment";

function createUrl(actionName: string): string {
  return `${environment.apiBaseUrl}${environment.apiUrl}${environment.apiVersion}${actionName}`;
}

export const authApiResources = {
  login :  createUrl('login'),
  logout :  createUrl('logout'),
  forgotPassword: createUrl('forgot-password'),
  resetPassword: createUrl('reset-password'),
  userPermissions: createUrl('auth/user/permissions'),
  userUserDetail: createUrl('auth/user-detail'),
  userOfficeSetting: createUrl('auth/user-office-setting')
};
