import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DropdownDataProcessingService {

  constructor() { }
  processData(data: any, key: string = 'title', statusKey: string = 'status', includeInActiveList: any = []) {
    return data
      .filter((item: any) => item[statusKey] == 1 || includeInActiveList.includes(item.id))
      .map((item: any) => {
        if (item[statusKey] == 0) {
          // Modify the dynamic key field
          item[key] = `${item[key]} (In Active)`;
          item['disabled'] =  true;
        }
        return item;
      });
  }


}
