import {environment} from "../../../../environments/environment";

function createUrl(actionName: string): string {
  return `${environment.apiBaseUrl}${environment.apiUrl}${environment.apiVersion}${actionName}`;
}

export const ScopeApiResources = {
  list:  createUrl('scopes'),
  groupedList:  createUrl('scopes/users'),
  userSelectedScopesList:  createUrl('user/scope'),
  create:  createUrl('scopes/create'),
  detail:  createUrl('scopes'),
  update:  createUrl('scopes'),
  delete:  createUrl('scopes'),
  divisions:  createUrl('divisions'),
  attachScope:  createUrl('user/scope/attach'),
  detachScope:  createUrl('user/scope/detach'),

};
