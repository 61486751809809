<ng-container [formGroup]="adminInformation">
<div class="card-body">
    <h2>Admin Information <mat-slide-toggle formControlName="tax_exempt">Tax Exempt?</mat-slide-toggle></h2>
  <div class="form-row" *ngIf="!isEdit">
    <div class="form-group col-12">
      <label for="sales_rep_id" class="custom_label">Sales Rep*</label>
      <ng-select
        id="sales_rep_id"
        [items]="staffs"
        placeholder="Select Sales Rep"
        [closeOnSelect]="true"
        formControlName="sales_rep_id"
        bindLabel="{{coreService.ngSelectBindStaffLabel}}"
      >
      </ng-select>
      <app-validation-error [formGroup]="adminInformation" [controlName]="'sales_rep_id'"></app-validation-error>
    </div>
  </div>

  <div class="form-row">
    <div class="form-group col-12">
      <label class="custom_label">Default Payment Methods</label>
<!--      <app-select-box-->
<!--        id="payment_method"-->
<!--        [name]="'payment_method'"-->
<!--        [items]="paymentMethods"-->
<!--        formControlName="payment_method"-->
<!--        [placeholder]="'Select Payment Method'"-->
<!--        [selectedItem]="onModelValueChanged?.default_payment_method"-->
<!--      ></app-select-box>-->
      <ng-select
        [items]="paymentMethods"
        formControlName="payment_method"
        [placeholder]="'Select Payment Method'"
        [closeOnSelect]="true"
        bindLabel="name"
      >
      </ng-select>
    </div>
  </div>

      <div class="form-row">
        <div class="form-group col-12">
          <label for="markup" class="custom_label">Preferred Mark Up</label>
          <div class="input_with_icon">
             <span class="icon_wrap"><!--<img src="../../../../assets/img/dollar_sign.svg" alt="icon"> --> %</span>
          <input
            currencyMask
            [options]="currencyMaskOptions"
            type="text" step="0.01" formControlName="markup" id="markup"  class="form-control" placeholder="markup" name="markup">
          </div>
        </div>
      </div>


    <!--<div class="form-row">
      <div class="form-group col-12">
        <label for="tax_class" class="custom_label">Tax Class</label>
        <app-form-select
          id="tax_class"
          [items]="taxAndDiscountClasses"
          [placeholder]="'Tax Class'"
          formControlName="tax_class"
        ></app-form-select>
      </div>
    </div>


    <div class="form-row">
      <div class="form-group col-12">
        <label for="disc_class" class="custom_label">Discount Class</label>
        <app-form-select
          id="disc_class"
          [items]="taxAndDiscountClasses"
          [placeholder]="'Discount Class'"
          formControlName="disc_class"
        ></app-form-select>
      </div>
    </div>-->


      <div class="form-row">
        <div class="form-group col-12">
          <label for="lien_period" class="custom_label">Lien Period</label>
          <div class="input_with_icon">
            <span class="icon_wrap"><!--<img src="../../../../assets/img/dollar_sign.svg" alt="icon"> --> Days</span>
            <input type="number" min="0" formControlName="lien_period" id="lien_period" class="form-control" placeholder="Lien Period" name="lien_period">
          </div>
        </div>
      </div>


      <div class="form-row">
        <div class="form-group col-12">
          <label class="custom_label">Source</label>
<!--          <app-select-box-->
<!--            id="source"-->
<!--            [name]="'source_id'"-->
<!--            [items]="sources"-->
<!--            formControlName="source"-->
<!--            [placeholder]="'Source'"-->
<!--            [selectedItem]="onModelValueChanged?.source?.id"-->
<!--          ></app-select-box>-->
          <ng-select
            [items]="sources"
            formControlName="source"
            [placeholder]="'Source'"
            bindLabel="name"
          >
          </ng-select>
        </div>
      </div>

      <div class="form-row">
        <div class="form-group col-12">
          <label for="risk" class="custom_label">Risk Level</label>
          <div class="input_with_icon">
            <span class="icon_wrap"><img src="../../../../assets/img/dollar_sign.svg" alt="icon"></span>
            <input type="text"
                   currencyMask
                   [options]="currencyMaskOptions"
                   formControlName="risk_level"
                   (keyup)="onValueInput($event)"
                   id="risk_level"
                   class="form-control"
                   placeholder="Risk Level"
                   name="risk_level">
          </div>
          <mat-slider
            (input)="onInputChange($event)"
            id="risk"
            class="form-control no-boarder-field"
            formControlName="risk"
            thumbLabel [displayWith]="formatLabel"
            tickInterval="1000"
            step="1000"
            min="0"
            max="1000000"
            aria-label="units">
          </mat-slider>
        </div>
      </div>
    </div>


</ng-container>
