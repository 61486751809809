import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {BackendApiService} from "../../../../services/backend-api.service";
import {UntypedFormControl, UntypedFormGroup, Validators} from "@angular/forms";
import {ToasterService} from "../../../../services/core/toaster.service";
import {formatDate} from "@angular/common";
import {ConfirmationPopupComponent} from "../../confirmation-popup/confirmation-popup.component";
import {NgDialogAnimationService} from "ng-dialog-animation";
import { ExportPdfSharedComponent } from '../../export-pdf/export-pdf.component';
import {AppConstants} from "../../../../constants/app.constants";

@Component({
  selector: 'app-ticket-detail-popup',
  templateUrl: './ticket-detail-popup.component.html',
  styleUrls: ['./ticket-detail-popup.component.scss']
})
export class TicketDetailPopupComponent implements OnInit {

  ticketID: any ;
  module_id: any ;
  model: any = {};
  attachedFiles: any = [];
  allowUploadFile: boolean = false;
  multipleUpload: boolean = true;
  allowedFileTypes: any = ['.jpg', '.jpeg', '.gif', '.png','.pdf'];
  statuses: any = [];
  attachmentsArr = [];
  attachmentsToAdd = [];
  comments:any =[];

  public sectionForm: UntypedFormGroup = new UntypedFormGroup({
    note: new UntypedFormControl(null,[Validators.required]),
    attached_file: new UntypedFormControl([]),
  })
  constructor(
    public dialogRef: MatDialogRef<TicketDetailPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public backendApiService: BackendApiService,
    public dialog: NgDialogAnimationService,
    public toasterService: ToasterService
  ) {
    this.ticketID = this?.data?.id;
    this.module_id = this?.data?.module_id;
    this.allowedFileTypes =  AppConstants.documentFileTypes;
  }

  ngOnInit(): void {
    this.getComment();
    this.getTicketStatuses();
    this.getTicketByID();
  }

  getComment(){
    this.backendApiService.postApi(`ticket/get-comments/${this.ticketID}`,'')
      .subscribe((response:any)=>{
        console.log(response);
        this.comments = response?.data?.data || [];
        // this.model = response?.data.data;
      })
  }

  getTicketByID() {
    this.backendApiService.getApi(`ticket/${this.ticketID}`)
      .subscribe((response: any) => {
        console.log('response',response);
        this.model = response?.data;
      })
  }

  getFormData() {
    const form = this.sectionForm?.value;
    return {
      ticket_id: this.model?.id,
      note: form?.note,
      attached_files: this.attachedFiles
    }
  }

  generatePDF() {
    const dialogRef = this.dialog.open(ExportPdfSharedComponent, {
      width: '30%',
      // height: '80%',
      data: {
        moduleName:'Ticket'
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        // this.getTemplates(result);
        this.toasterService.showWarning('PDF export not available.');
      }
    });
  }

  getTemplates(data: any) {
    console.log('getTemplates data====', data);
    // this will be uncommented when backend ready
    // Object.values(data?.templates)?.map((templateValue:any,) => {
    //   const url = `${environment.apiBaseUrl}/submittal/generate-pdf?id=${this.submittalID}&template_id=${templateValue}&output=${data?.type}`
    //   this.previewPdf(url);
    // })
  }

  save() {
    if(this.sectionForm.invalid) {
      this.toasterService.showErrorMessage('Please type notes');
      return;
    }
    this.backendApiService.postApi(`ticket/add-comment/${this.ticketID}`,{comment:this.getFormData().note, attachment_ids:this.getFormData().attached_files})
      .subscribe((response: any) => {
        this.toasterService.showSuccessMessage(response?.data?.message);
        // adds new notes to the notes array
        this.allowUploadFile = false;
        this.getComment();
        this.attachedFiles = [];
        this.attachmentsArr = [];
        this.allowUploadFile = false;
        this.sectionForm.get('note')?.setValue(null);
      })
  }

  fileUpload() {
    this.allowUploadFile = !this.allowUploadFile;
  }

  attachment(event: any) {
    const data  = event?.attachment;
    if(Array.isArray(data)){
      this.attachmentsArr = data as any;
      this.attachmentsToAdd = this.attachmentsArr.map((file: any) => file.attachment_id) as any;
    }
    this.attachedFiles =  this.attachedFiles.concat(this.attachmentsToAdd);
  }

  downloadFile(file: any) {
    console.log('file',file?.url);
  }



  onConfirmation(): void {
    const dialogRef = this.dialog.open(ConfirmationPopupComponent, {
      width: '500px',
      height: '300px',
      data: {
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result) {
        this.onDelete();
      }
    });
  }

  onDelete = () => {
    this.backendApiService.deleteApi('ticket/'+this.ticketID)
      .subscribe((response: any) => {
        this.close('delete');
      })
  }
  close = (data: any = null) => {
    this.dialogRef.close(data);
  }

  getTicketStatuses() {
    this.backendApiService.getApi('statuses/ticket')
      .subscribe((response: any) => {
        this.statuses = response?.data;
      })
  }

  getNameInitials(name: any) {
    return name.substring(0,2)
  }

  onChangeStatus(data: any) {
    this.backendApiService.postApi('ticket/change-status/'+this.model?.id,{status:data?.id})
      .subscribe((response: any) => {
        this.toasterService.showSuccessMessage(response?.data.message);
        this.getTicketByID();
      })
  }

  handleKeydown(event: KeyboardEvent) {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault(); // Prevent the default action (new line)
      this.save();
    }
  }
}
