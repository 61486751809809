import {throwError as observableThrowError,  Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { tap, catchError } from 'rxjs/operators';
import {JsonService} from "../../../services/core/json.service";
import {ScopeApiResources} from "./scope.constants";
@Injectable({
  providedIn: 'root'
})
export class ScopeDataService {
  private baseURL;

  constructor(
    private http: HttpClient,
    private jsonService: JsonService,
  ) {
    this.baseURL = ScopeApiResources;
  }

  list(model: any = null): Observable<any> {
    const endPoint = `${this.baseURL.list}`;
    return this.http.post<any>(endPoint, model)
      .pipe(tap(data => (this.jsonService.doStringify(data))),
      catchError(this.errorHandlerMessage));
  }

  create(model: any): Observable<any> {
    const endPoint = `${this.baseURL.create}`;
    return this.http.post<any>(endPoint, model)
      .pipe(tap(data => (this.jsonService.doStringify(data))),
      catchError(this.errorHandlerMessage));
  }

  detail(id:any): Observable<any> {
    const endPoint = `${this.baseURL.detail}/${id}`;
    return this.http.get<any>(endPoint)
      .pipe(tap(data => (this.jsonService.doStringify(data))),
      catchError(this.errorHandlerMessage));
  }

  update(id:any, model: any): Observable<any> {
    const endPoint = `${this.baseURL.update}/${id}`;
    return this.http.patch<any>(endPoint, model)
      .pipe(tap(data => (this.jsonService.doStringify(data))),
      catchError(this.errorHandlerMessage));
  }

  delete(id:any): Observable<any> {
    const endPoint = `${this.baseURL.delete}/${id}`;
    return this.http.delete<any>(endPoint)
      .pipe(tap(data => (this.jsonService.doStringify(data))),
      catchError(this.errorHandlerMessage));
  }

  divisionsList(model: any = null): Observable<any> {
    const endPoint = `${this.baseURL.divisions}`;
    return this.http.get<any>(endPoint)
      .pipe(tap(data => (this.jsonService.doStringify(data))),
        catchError(this.errorHandlerMessage));
  }
  userSelectedScopesList(model: any = null): Observable<any> {
    const endPoint = `${this.baseURL.userSelectedScopesList}`;
    return this.http.get<any>(endPoint)
      .pipe(tap(data => (this.jsonService.doStringify(data))),
        catchError(this.errorHandlerMessage));
  }
  groupedList(model: any = null): Observable<any> {
    const endPoint = `${this.baseURL.groupedList}`;
    return this.http.get<any>(endPoint)
      .pipe(tap(data => (this.jsonService.doStringify(data))),
        catchError(this.errorHandlerMessage));
  }
  attachScope(model: any): Observable<any> {
    const endPoint = `${this.baseURL.attachScope}`;
    return this.http.post<any>(endPoint, model)
      .pipe(tap(data => (this.jsonService.doStringify(data))),
        catchError(this.errorHandlerMessage));
  }
  detachScope(id:any, model: any): Observable<any> {
    const endPoint = `${this.baseURL.detachScope}/${id}`;
    return this.http.post<any>(endPoint, model)
      .pipe(tap(data => (this.jsonService.doStringify(data))),
        catchError(this.errorHandlerMessage));
  }
  errorHandler(error: HttpErrorResponse) {
    return observableThrowError(error.error || 'Server Error');
  }

  errorHandlerMessage(error: HttpErrorResponse) {
    return observableThrowError(error.error || 'Server Error');
  }
}
