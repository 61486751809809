import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EchartComponent } from './echart/echart.component';
import { NgxEchartsModule } from 'ngx-echarts';



@NgModule({
  declarations: [
    EchartComponent
  ],
  imports: [
    CommonModule,
    NgxEchartsModule.forRoot({
      echarts: () => import('echarts')
    })
  ],
  exports:[EchartComponent]
})
export class EchartModule { }
