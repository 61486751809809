import {environment} from "../../../../environments/environment";

function createUrl(actionName: string): string {
  return `${environment.apiBaseUrl}${environment.apiUrl}${environment.apiVersion}${actionName}`;
}

export const ProposalApiResources = {
  list:  createUrl('proposals'),
  create:  createUrl('proposals/create'),
  detail:  createUrl('proposals'),
  update:  createUrl('proposals'),
  delete:  createUrl('proposals'),
  getRevisionBidders:  createUrl('proposals/get-bidder-revisions'),
  cloneScope:  createUrl('proposals/clonescope'),

  searchItems:  createUrl('proposals/search-items'),
  updateDisplayOptions:  createUrl('proposals/display-options'),
  calculations: createUrl('proposals/calculations')

};
