import { Component, ElementRef, HostListener, Input, OnInit, Renderer2, SimpleChanges } from '@angular/core';
import { ECharts } from 'echarts';
import { chartOptions } from '../../echart/echart-constant/pie-chart-options';
import { EChartOption } from "echarts";
import { CurrencyPipe } from '@angular/common';
import { log } from 'console';

@Component({
  selector: 'app-account-balance-chart',
  templateUrl: './account-balance-chart.component.html',
  styleUrls: ['./account-balance-chart.component.scss'],
  providers: [CurrencyPipe] // Add CurrencyPipe to the providers array

})
export class AccountBalanceChartComponent implements OnInit {
  chartInstance!: ECharts | null;
  chartOptions: EChartOption = { ...chartOptions }
  @Input() chartValueLabel: string = 'Proposal Value'
  scopeChartView: boolean = false;
  @Input() scopeList: any[] = [];
  @Input() statistics: any
  @Input() model: any
  chartValueLabelCopy: string = ''
  @Input() taxValue: number=0
  private _chartAmountValue: any = "$0.00";
  chartAmountValueCopy: string = ''

  @Input() set chartAmountValue(value: any) {
    this._chartAmountValue = value;
    this.chartAmountValueCopy=value
    // You can add additional logic here if needed when the value changes
  }

  get chartAmountValue(): any {
    return this._chartAmountValue;
  }
  private clickListener: () => void;

  constructor(private currencyPipe: CurrencyPipe, private elementRef: ElementRef, private renderer: Renderer2, private elRef: ElementRef) {
    this.clickListener = this.renderer.listen('document', 'click', (event: MouseEvent) => this.onDocumentClick(event));
  }


  ngOnInit(): void { }

  ngAfterViewInit(): void {
    //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    //Add 'implements AfterViewInit' to the class.
    this.chartValueLabelCopy = this.chartValueLabel
  }

  ngOnChanges(changes: SimpleChanges): void {
    //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    //Add '${implements OnChanges}' to the class.
    if (changes) {
      if (changes['scopeList']?.currentValue) {
        this.scopeList = changes['scopeList']?.currentValue
      }
      if (changes['statistics']?.currentValue) {
        this.statistics = changes['statistics']?.currentValue
      }
      this.setChartOptions()
    }
  }

  setChartOptions() {
    // Use a small delay to ensure the chart clears before applying new options
    setTimeout(() => {
      if (!this.scopeChartView) {
        this.setProfitAndCostData();
      } else if (this.scopeChartView) {
        this.setScopeListData();
      }

      this.chartInstance?.setOption(this.chartOptions, { notMerge: true });
      console.log('Updated series:', this.chartOptions);
      this.listenChartEvent();
    }, 100); // 100ms delay to ensure the clear takes effect
  }

  getChartInstance(event: ECharts) {
    this.chartInstance = event;

    if (this.chartInstance) {
      // Clear the chart before setting new options
      this.chartInstance.clear();
      this.setChartOptions()

    } else {
      console.error('Chart instance not initialized');
    }
  }

  private setProfitAndCostData() {
    const profitValue = Math.abs(Number(this.statistics?.profit?.total)); // Use absolute value
    const costValue = Math.abs(Number(this.statistics?.cost?.totalCost)); // Use absolute value  
    const taxValue=Math.abs(Number(this.taxValue)); // Use absolute value  
    const data = [
      { value: profitValue, name: 'Total Profit', id: 'profit', color: '#008000' },
      { value:costValue, name: 'Total Cost', id: 'cost', color: '#FF0000' },
      { value:taxValue, name: 'Tax', id: 'tax', color: '#6e2c00' },
    ];
    
    this.setChartSeriesData(data, 'Proposal');
    this.setTooltipFormatterForProfitAndCost();
  }

  private setScopeListData() {
    this.scopeList.forEach((x: any) => {
      x.name = x.scope_name;
      x.value = Math.abs(Number(x.total));
    });

    this.setChartSeriesData(this.scopeList, 'Proposal');
    this.setTooltipFormatterForScopeList();
  }

  private setChartSeriesData(data: any[], seriesName: string) {
    // Ensure series is defined and access the first series
    if (this.chartOptions.series && this.chartOptions.series.length > 0) {
      this.chartOptions.series[0].name = seriesName;
      this.chartOptions.series[0].data = data; // Access the first series and set its data
    } else {
      console.error('Series array is undefined or empty');
    }
  }

  private setTooltipFormatterForProfitAndCost() {
    this.chartOptions.tooltip = {
      position: 'right',
      // position: (point: any, params, dom, rect, size: any) => this.calculateTooltipPosition(point, size),
      formatter: (params: any) => {
        const formattedValue = this.currencyPipe.transform(params.value, 'USD');
        return `${params.name}: ${formattedValue}`;
      }
    };
  }

  private setTooltipFormatterForScopeList() {
    this.chartOptions.tooltip = {
      position: 'right',
      // position: (point: any, params, dom, rect, size: any) => this.calculateTooltipPosition(point, size),
      formatter: (params: any) => {
        let profit = this.currencyPipe.transform(this.statistics.profit.total, 'USD');
        let cost = Number(params.data.cost.total)
        let name = params.name;
        let costWithTax = this.currencyPipe.transform(cost, 'USD');
        let tax = this.currencyPipe.transform(params.data.total_tax, 'USD');
        let total = this.currencyPipe.transform(params.data.total, 'USD');
        return `Scope: ${name}<br> Profit: ${profit}<br> Cost: ${costWithTax} <br> Tax: ${tax} <br> Total: ${total}`;
      }
    };
  }

  private calculateTooltipPosition(point: any, size: any) {
    const contentSize = (size as { contentSize: [number, number] }).contentSize;
    const x = point[0] + 10; // Add an offset for x-position

    // Determine the y position based on cursor position
    let y;
    if (point[1] - contentSize[1] - 10 < 0) {
      // If there’s not enough space above the cursor, position below it
      y = point[1] + 10; // Add a slight offset below the cursor
    } else {
      // Position above the cursor otherwise
      y = point[1] - contentSize[1] - 10; // Adjust for tooltip height
    }

    return [x, y]; // Return the new tooltip position
  }

  currentlyHighlighted = {
    seriesIndex: -1,
    dataIndex: -1 // Optionally, if you want to highlight the first data point in the series
  };


  listenChartEvent() {
    if (this.chartInstance) {
      // Remove previous event listeners
      this.chartInstance.off('click');
      this.chartInstance.off('mouseover');
      // Click listener for series item highlighting
      this.chartInstance.on('click', (params: any) => {
        this.listenProposalChartHoverEvent(params)
      });

        // Hover listener for series item highlighting
    this.chartInstance.on('mouseover', (params: any) => {
      this.listenProposalChartHoverEvent(params);
    });

      // Force the chart to resize and repaint
      setTimeout(() => {
        this.chartInstance?.resize();
      }, 200);
    }
  }



  listenProposalChartHoverEvent(params: any) {
    // De-highlight the previously highlighted series item
    if (this.currentlyHighlighted) {
      this.chartInstance?.dispatchAction({
        type: 'downplay',
        seriesIndex: this.currentlyHighlighted.seriesIndex,
        dataIndex: this.currentlyHighlighted.dataIndex
      });
    }

    // Highlight the clicked series item
    this.chartInstance?.dispatchAction({
      type: 'highlight',
      seriesIndex: params.seriesIndex,
      dataIndex: params.dataIndex
    });

    // Store the currently highlighted series and data index
    this.currentlyHighlighted = {
      seriesIndex: params.seriesIndex,
      dataIndex: params.dataIndex
    };

    console.log('Hovered data:', params);
    if (params?.data?.id === 'profit') {
      this.chartValueLabel = 'Total Profit';
      this.chartAmountValueCopy = this.currencyPipe.transform(this.statistics.profit.total, 'USD') as string;
      console.log(this.chartAmountValue)
    } else if (params?.data?.id === 'cost') {
      this.chartValueLabel = 'Total Cost';
      this.chartAmountValueCopy = this.currencyPipe.transform(Number(this.statistics.cost.totalCost), 'USD') as string;
    }
    else if (params?.data?.id === 'tax') {
      this.chartValueLabel = 'Total Tax';
      this.chartAmountValueCopy = this.currencyPipe.transform(Number(this.taxValue), 'USD') as string;
    }
  }

  onToggleChange(event: any) {
    // Add additional logic based on the toggle state here
    this.getChartInstance(this.chartInstance as ECharts)
  }

    // Event handler for clicks outside the chart
    private onDocumentClick(event: MouseEvent | any): void {
      const clickedElement = event.target as HTMLElement;
  
      // Check if the clicked element is inside the chart container
      const isInsideChart = this.elRef.nativeElement.contains(clickedElement);
  
      if (isInsideChart) {
        // Handle clicks inside the chart container (on the canvas or empty space)
        const model = (this.chartInstance as any)?.getModel() as any;
        if (model) {
          // Check if the click event was not on a series (meaning it's on an empty canvas/background)
          const eventParams = (this.chartInstance as any)?.getZr().handler.findHover(event.offsetX, event.offsetY);
          if (!eventParams || !eventParams.target) {
            this.outsideClickContainer()
            console.log('Clicked on the chart background or empty space.');
          }
        }
      } else {
        // this.outsideClickContainer()
        console.log('Clicked outside the chart container.');
      }
    }

    outsideClickContainer(){
      this.chartInstance?.dispatchAction({
        type: 'downplay',
        seriesIndex: this.currentlyHighlighted.seriesIndex,
        dataIndex: this.currentlyHighlighted.dataIndex
      });
      this.currentlyHighlighted = {
        seriesIndex: -1,
        dataIndex: -1
      }
      this.chartValueLabel = this.chartValueLabelCopy
      this.chartAmountValueCopy = this.chartAmountValue;
    }

}
