import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {UntypedFormControl, UntypedFormGroup, Validators} from "@angular/forms";
import {BackendApiService} from "../../../../services/backend-api.service";
import {ToasterService} from "../../../../services/core/toaster.service";
import {DatePipe, formatDate} from "@angular/common";
import {
  ObserverParticipantPopupComponent
} from "./add-checklist/observer-participant-popup/observer-participant-popup.component";
import {NgDialogAnimationService} from "ng-dialog-animation";
import {TaskDataService} from "../task.data.service";
import {DialogService} from "../../../../services/core/dialog.service";
import {StaffService} from "../../../../services/staff/staff.service";
import {CommunicationServiceService} from "../../../widgets/services/communication-service.service";
import {AppConstants} from "../../../../constants/app.constants";

@Component({
  selector: 'app-add-task',
  templateUrl: './add-task.component.html',
  styleUrls: ['./add-task.component.scss']
})
export class AddTaskComponent implements OnInit {

  public CONST_HIGH_PRIORITY: boolean = false;
  public CONST_TIME_PLANNING: boolean = false;
  public CONST_CREATED_BY: boolean = false;
  public CONST_OBSERVER: boolean = false;
  public CONST_PARTICIPANT: boolean = false;
  public TASK_MODULE_ID: number = 7;
  public CONST_RESPONSIBLE_PERSON_TYPE: string = 'responsible_person';
  public CONST_CREATED_BY_TYPE: string = 'created_by';
  public CONST_OBSERVER_TYPE: string = 'observer';
  public CONST_PARTICIPANT_TYPE: string = 'participant';
  moduleID: number;
  referenceID: number;
  taskID: number;
  isTaskModule:boolean = false;
  isEdit:boolean = false;
  model:any = {};
  responsibleUsersArray: any = [];
  createdByArray: any;
  observerArray: any = [];
  participantArray: any = [];
  stages: any = [];
  templates: any = [];
  selectedStage: any = {};
  searchParams = {
    type: '',
    module_id: '',
    search: ''
  };
  selectedItem: any = {};
  showSearchInput: boolean = true;
  modulesList: any = [];
  mentions: any = [];
  selectedMentionedUsers: any  = [];
  attachmentsArr = [];
  attachmentsToAdd = [];
  public allowedFileTypes: any = [];

  public taskForm: UntypedFormGroup = new UntypedFormGroup({
    title: new UntypedFormControl(null,[Validators.required]),
    description: new UntypedFormControl(null),
    priority: new UntypedFormControl(false),
    responsible_person: new UntypedFormControl([]),
    participants: new UntypedFormControl([]),
    observers: new UntypedFormControl([]),
    is_responsible_person: new UntypedFormControl(false),
    is_participant: new UntypedFormControl(false),
    is_observer: new UntypedFormControl(false),
    is_time_planning: new UntypedFormControl(false),
    due_date: new UntypedFormControl(null),
    start_date: new UntypedFormControl(null),
    date_finished: new UntypedFormControl(null),
    is_created_by: new UntypedFormControl(false),
    created_by: new UntypedFormControl(null),
    task_type_id: new UntypedFormControl(null),
    duration: new UntypedFormControl(null),
    checklists: new UntypedFormControl(null),
    is_template: new UntypedFormControl(false),
    stage_id: new UntypedFormControl(null),
    module_id: new UntypedFormControl(null),
    reference_id: new UntypedFormControl(null),
  })
  constructor(
    public dialogRef: MatDialogRef<AddTaskComponent>,
    public backendApiService: BackendApiService,
    public toasterService: ToasterService,
    public dialog: NgDialogAnimationService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public taskDataService: TaskDataService,
    public dialogService: DialogService,
    public staffService: StaffService,
    public scrumCommunication: CommunicationServiceService
  ) {
    this.moduleID = this.data?.moduleID;
    this.referenceID = this.data?.referenceID;
    this.isTaskModule = this.data?.isTaskModule;
    this.isEdit = this.data?.isEdit;
    this.model = this.data?.model;
    this.taskID = this.data?.id;
    this.allowedFileTypes =  AppConstants.documentFileTypes;
  }

  ngOnInit(): void {
    this.taskForm.get('module_id')?.setValue(this.moduleID);
    this.taskForm.get('reference_id')?.setValue(this.referenceID);
    this.getModules();
    if (this.moduleID){
      this.getStagesOfModule(this.moduleID);
    }
    this.getTaskTemplates();
  }

  ngAfterViewInit() {
    if(this.isEdit) {
      this.setDataOnEdit();
    }
  }

  setDataOnEdit() {
    const filesData = this.model.relations.attach_files.map((file: any)=>{
      return {
        attach_file: file,
        attachment_id: file.id
      }
    });
    if (this.model?.priority == 3){
      this.changePriority();
    }
    this.attachmentsArr = filesData;
    this.taskForm.setValue({
      title: this.model?.name,
      description: this.model?.description,
      priority: this.model?.priority == 3,
      responsible_person: new UntypedFormControl([]),
      participants: new UntypedFormControl([]),
      observers: new UntypedFormControl([]),
      is_responsible_person: this.model?.is_responsible_person,
      is_participant: this.model?.is_participant,
      is_observer: this.model?.is_observer,
      is_time_planning: this.model?.is_time_planning,
      due_date: this.model?.due_date ?? null,
      start_date: this.model?.start_date ?? null,
      date_finished: this.model?.date_finished ? formatDate(this.model?.date_finished,"yyyy-MM-dd","en-US") : null,
      is_created_by: this.model?.is_created_by,
      created_by: this.model?.created_by ? this.model?.created_by?.id : null,
      task_type_id: 1,
      duration: this.model?.duration,
      checklists: this.model?.check_list,
      is_template: this.taskID ? this.model?.is_template : false,
      stage_id: this.model?.stage?.id,
      module_id: this.model?.module?.id,
      reference_id: this.model?.reference?.id,
    })

    this.selectedStage = this.model?.stage;

    this.createdByArray = this.model?.created_by;
    this.CONST_CREATED_BY = true;
    this.CONST_OBSERVER = true;
    this.CONST_PARTICIPANT = true;

    this.model?.responsible_persons?.map((data: any) => {
      this.responsibleUsersArray.push({id:data?.id,full_name:data?.full_name})
    })

    this.model?.observers?.map((data: any) => {
      this.observerArray.push({id:data?.id,full_name:data?.full_name})
    })

    this.model?.participants?.map((data: any) => {
      this.participantArray.push({id:data?.id,full_name:data?.full_name})
    })

    this.model?.mentions?.map((data: any) => {
      this.selectedMentionedUsers.push({id:data?.id,full_name:data?.full_name})
    })
  }

  getStagesOfModule(id:any) {
    this.backendApiService.getApi('stages?module_id='+id)
      .subscribe((response: any) => {
        this.stages = response.data;
      })
  }

  getTaskTemplates() {
    this.backendApiService.postApi('task-template','')
      .subscribe((response: any) => {
        this.templates = response.data;
      })
  }
  getModules() {
    this.backendApiService.getApi('modules/type?type=task')
      .subscribe((response: any) => {
        this.modulesList = response?.data
      })
  }

  addStage(stage: any) {
    // console.log('stage',stage);
    this.selectedStage = stage;
    this.taskForm.get('stage_id')?.setValue(stage?.id);
  }

  changePriority(event: any = '') {
    this.CONST_HIGH_PRIORITY = !this.CONST_HIGH_PRIORITY;
  }

  hideShowTimePlanning() {
    this.CONST_TIME_PLANNING = !this.CONST_TIME_PLANNING;
  }

  hideShowCreatedBy() {
    this.CONST_CREATED_BY = !this.CONST_CREATED_BY;
  }

  hideShowObserver() {
    this.CONST_OBSERVER = !this.CONST_OBSERVER;
  }

  hideShowParticipant() {
    this.CONST_PARTICIPANT = !this.CONST_PARTICIPANT;
  }

  getFileID(event: any) {
    const data  = event?.attachment;
    if(Array.isArray(data)){
      this.attachmentsArr = data as any;
      this.attachmentsToAdd = this.attachmentsArr.map((file: any) => file.attachment_id) as any;
    }
  }

  getFormData() {
    const form = this.taskForm.value;
    return {
      // parent_id: this.referenceID,
      module_id: form.module_id,
      reference_id: form.reference_id,
      name: form.title,
      description: form.description,
      priority: form.priority == true ? 3 : 1,
      responsible_person: this.responsibleUsersArray?.map((x: any) => x.id),
      participants: this.participantArray?.map((x: any) => x.id),
      observers:this.observerArray.map((x: any) => x.id),
      mentions:this.selectedMentionedUsers?.map((x: any) => x.id),
      is_participant: this.CONST_PARTICIPANT,
      is_observer: this.CONST_OBSERVER,
      is_time_planning: this.CONST_TIME_PLANNING,
      due_date: form?.due_date ? formatDate(form?.due_date,'yyyy-MM-dd','en_US') : null,
      start_date: form?.start_date ? formatDate(form?.start_date,'yyyy-MM-dd','en_US') : null,
      date_finished: form?.date_finished ? formatDate(form?.date_finished,'yyyy-MM-dd','en_US') : null,
      is_created_by: this.CONST_CREATED_BY,
      created_by: form.created_by,
      task_type_id: form?.task_type_id ?? 1,
      stage_id: form?.stage_id,
      duration: form?.duration,
      check_list: form?.checklists,
      is_template: form.is_template,
      reminder: 'reminder',
      attachment_ids: this.attachmentsToAdd
    }
  }

  save() {
    const form = this.taskForm.value;
    let isInvalid = false;

    if (!form.title){
      isInvalid = true;
      this.toasterService.showWarning('Name', 'Name field is required.');
    }

    if (!form.reference_id){
      isInvalid = true;
      this.toasterService.showWarning('Reference', 'Reference Number field is required.');
    }

    if (!form.module_id){
      isInvalid = true;
      this.toasterService.showWarning('Module', 'Module Field is Required.');
    }

    if (isInvalid){
      return true;
    }
    const service = this.taskID ? this.backendApiService.patchApi(`tasks/${this.model?.id}`,this.getFormData()) :
     this.backendApiService.postApi('tasks/create',this.getFormData())

      service.subscribe((resposne: any) => {
        this.toasterService.showSuccessMessage(resposne.data?.message);
        this.close(true);
      })
    return true;
  }

  getChecklists(event: any) {
    console.log('event',event);
    this.taskForm.get('checklists')?.setValue(event);
  }


  openUsersPopup(event: any,type: any) {
    const position = event.target.getBoundingClientRect();
    const dialogRef = this.dialog.open(ObserverParticipantPopupComponent,{
      width: '400px',
      height: '400px',
      // position: {top:`${position.top}px`, left: `${position.left}px`},
      data: {
        type: type,
      }
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      console.log('result',result);
      if(result) {
        if(result.type == this.CONST_RESPONSIBLE_PERSON_TYPE) {
          this.addUser( this.responsibleUsersArray, result.user);
        } else if(result.type == this.CONST_CREATED_BY_TYPE) {
          this.taskForm.get('created_by')?.setValue(result.user?.id);
          this.createdByArray = result.user
          // this.createdByArray.push(result.user)
        } else if(result.type == this.CONST_OBSERVER_TYPE) {
          this.addUser( this.observerArray, result.user);
        } else if(result.type == this.CONST_PARTICIPANT_TYPE) {
          this.addUser( this.participantArray, result.user);
        }
      }
    })
  }

  addUser(usersList: any, user: any){
    const alreadyExistCheck = usersList.find((x:any) => x.id == user.id);
    if (!alreadyExistCheck){
      usersList.push(user);
    }
  }

  remove(index: any,dataArray: any) {
    dataArray.splice(index,1);
  }

  selectedModule(event: any) {
    if (event){
    this.searchParams.type = event?.slug;
    this.searchParams.module_id = event?.id;
    this.searchParams.search = '';
    this.selectedStage = {};
    this.taskForm.get('module_id')?.setValue(event?.id);
    this.getStagesOfModule(event?.id);
    }else{
      this.taskForm.get('module_id')?.setValue('');
    }
    this.taskForm.get('reference_id')?.setValue('');
    this.showSearchInput = true;
    this.selectedItem = '';
  }

  onItemSelect(event: any) {
    this.selectedItem = event;
    this.taskForm.get('reference_id')?.setValue(event?.id);
    this.showSearchInput = false;
  }

  updateSearchInputFlag() {
    this.showSearchInput = true;
    this.taskForm.get('reference_id')?.setValue(null);

  }

  populateTemplate(template: any) {
    this.close();
    this.dialogService.onOpenFromRightDialog(AddTaskComponent,{
      isEdit:true,
      model:template,
    },1300);
    this.dialogService.afterClose()?.subscribe((result: any) => {
      if(result) {
      }
    });
  }

  close = (data: any = null) => {
    if (data){
      this.scrumCommunication.setScrumBoardData(data);
    }
    this.dialogRef.close(data);
  }

  getUsers(event: any){
    const search = event;
    this.backendApiService.getApi(`staff/search?search=${search}`, [], false)
      .subscribe((response: any) => {
        this.mentions =  this.staffService.processStaff(response.data);;
      })
  }

  getSelectedUser(event: any){
    const index = this.selectedMentionedUsers.findIndex((x: any) => x.id == event?.id);
    console.log('index',index);
    if(index == -1) {
      this.selectedMentionedUsers.push(event);
    }
    console.log(this.selectedMentionedUsers);
  }

}
