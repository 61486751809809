<ng-container [formGroup]="customerForm">
  <div class="add_bider_draw">
<div class="form-row">
  <div class="form-group col-12">
    <label for="customer_id" class="custom_label">Customer *</label>
    <div class="input_with_external_btn">
<!--    <ng-select-->
<!--      id="customer_id"-->
<!--      [items]="customers"-->
<!--      placeholder="Select Customer"-->
<!--      [closeOnSelect]="true"-->
<!--      formControlName="customer_id"-->
<!--      bindLabel="{{coreService.ngSelectBindLabel}}"-->
<!--      (change)="onChangeCustomer($event)"-->
<!--    >-->
<!--    </ng-select>-->
      <app-scrollable-search-field
        class="d-flex w-100"
        id="customer"
        [dataService]="customerService"
        [dataServiceFunctionName]="'searchCustomers'"
        [searchPlaceHolder]="'Select Customer'"
        [labelKey]="'company_name'"
        [formDataMapper]="{'id': 'id', 'name' : 'company_name', 'contacts' : 'contacts','primary_contact': 'primary_contact','primary_sales_rep': 'primary_sales_rep'}"
        [keyNameToShowWhenSelected]="'name'"
        [inputVal]="customerName"
        [markCtrlTouched]="isFormTouched"
        (selectedValueChange)="onChangeCustomer($event)"
      ></app-scrollable-search-field>
    <button type="button" [appPermission]="createCustomerPermission" (click)="onCreateCustomer()" class="btn_add_more_sm">+</button>
  </div>
<!--    <app-validation-error [formGroup]="customerForm" [controlName]="'customer_id'"></app-validation-error>-->
  </div>
</div>

<div class="form-row">
  <div class="form-group col-12">
    <label for="contact_id" class="custom_label">Contact*</label>
    <div class="input_with_external_btn">
    <ng-select
      id="contact_id"
      [items]="contacts"
      placeholder="Select Contact"
      [closeOnSelect]="true"
      formControlName="contact_id"
      bindLabel="{{coreService.ngSelectBindLabel}}"
    >
    </ng-select>
    <button type="button"  [appPermission]="createContactPermission" (click)="onCreateContact()"class="btn_add_more_sm">+</button>
  </div>
    <app-validation-error [formGroup]="customerForm" [controlName]="'contact_id'"></app-validation-error>
  </div>
</div>
</div>
</ng-container>
