<div class="row job-value-wrap m-auto">
  <div class="col-md-4 align-self-center">
    <div class="proposal-echart-container">
      <app-echart (chartInstance)="getChartInstance($event)"></app-echart>
    </div>
  </div>

  <div class="col-md-8 align-self-center">
    <div class="row">
      <div class="col-md-7 col-12 align-self-center">
        <span>{{chartValueLabel}}</span>
        <h6>
          <h6>{{chartAmountValueCopy}}</h6>
        </h6>
      </div>

      <!-- Toggle Button -->
      <div class="toggle-container">
        <span class="toggle-label">Scope View</span>
        <img src="../../../../../assets/img/general_icons/chartview.svg" alt="fire icon" *ngIf="scopeChartView"
          (click)="onToggleChange($event)">
        <img src="../../../../../assets/img/general_icons/chartview-active.svg" alt="fire icon" *ngIf="!scopeChartView"
          (click)="onToggleChange($event)">
      </div>


      <div class="col-md-5 col-12" *ngIf="this.model?.activate_buyout == 1 ">
        <span class="header_label-right">
          <img src="../../../../../../assets/img/sales/proposal-edit/danger-icon.png" width="25"
            alt="danger icon for proposal override values">
          Active Buyout
        </span>
      </div>
    </div>
  </div>

</div>