import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormControl} from "@angular/forms";
import {debounceTime, distinctUntilChanged, filter, switchMap} from "rxjs";
import {finalize, tap} from "rxjs/operators";
import {ProductDataService} from "../../../inventory/product/product.data.service";
import {HelperService} from "../../../../services/helper.service";

@Component({
  selector: 'app-search-field',
  templateUrl: './search-field.component.html',
  styleUrls: ['./search-field.component.scss']
})
export class SearchFieldComponent implements OnInit {
  @Input() set clearResults(value: any) {
    console.log('clear Results',  value)
    this.filteredResult = [];
  }
  @Input() searchAbleDataService: any;
  @Input() searchAbleFunctionName: string = 'list';
  @Input() searchAbleParams: any =  {
    page: 1,
    per_page: 200,
    sort_by: 'code',
    sort_order: 'desc',
    // search: '',
    columns: [],
  };
  @Input() isStringParams: boolean = false;
  @Input() minLengthTerm = 2;
  @Input() searchPlaceHolder = 'Type to search';
  @Input() labelKey = 'title';
  @Input() defaultSearchValue = '';
  @Input() selectedItemBackGroundColor = '#eeeeeeee';

  @Output() onSelectValue = new EventEmitter<any>(true);
  @Output() onNoSelection = new EventEmitter<any>(true);
  @Output() onRemoveSelection = new EventEmitter<any>(true);

  //auto complete ts start
  searchCtrl = new FormControl();
  filteredResult: any;
  isLoading = false;
  isInputCurrentlyFocused = false;
  selectedSearchResult: any = "";
  selectedItem: any = "";
  //auto complete ts end
  onBlurSelectionInputCounter = 0
  constructor(
    public productDataService: ProductDataService,
    public helperService: HelperService,
  ) {
    this.searchAbleDataService = this.productDataService;
  }

  ngOnInit(): void {
    this.searchCtrl.setValue(this.defaultSearchValue);
    this.onInitSearchProduct();
  }
  async onFocusSelectionInput() {
    this.isInputCurrentlyFocused = true;
  }
  async onBlurSelectionInput() {
    this.isInputCurrentlyFocused = false;
    this.onBlurSelectionInputCounter++;
    const counter = this.onBlurSelectionInputCounter;
    await this.helperService.delay(400);
    if (counter == this.onBlurSelectionInputCounter && !this.isInputCurrentlyFocused) {
      if (typeof this.selectedSearchResult !== 'object' && this.selectedSearchResult)
      {
        this.onNoSelection.emit(this.selectedSearchResult);

      }
    }

  }
  onSelected() {
    this.selectedSearchResult = this.selectedSearchResult;
    this.selectedItem = this.selectedSearchResult;
    this.onSelectValue.emit( this.selectedItem);
  }

  displayWith(value: any) {
    return value?.title;
  }
  onInitSearchProduct(){
    this.searchCtrl.valueChanges
      .pipe(
        filter(res => {
          return res !== null && res.length >= this.minLengthTerm
        }),
        distinctUntilChanged(),
        debounceTime(1000),
        tap(() => {
          this.filteredResult = [];
          this.isLoading = true;
        }),
        // switchMap(value => this.http.get('http://www.omdbapi.com/?apikey=e8067b53'  + '&s=' + value)
        switchMap((value: any) => {
          if (this.isStringParams){
            this.searchAbleParams = value;
          }
          else {
            this.searchAbleParams.search = value;
            this.searchAbleParams.loadingService = 0;
          }
          return this.searchAbleDataService[this.searchAbleFunctionName](this.searchAbleParams)
            .pipe(
              finalize(() => {
                this.isLoading = false
              }),
            );
        })
      )
      .subscribe((data: any) => {
        this.filteredResult = data.data.data ? data.data.data : data.data;
      });
  }

  clearSelection() {
    this.selectedSearchResult = "";
    this.selectedItem  = "";
    this.filteredResult = [];
    this.onRemoveSelection.emit(true);
  }
}
