import { Component, OnInit, ElementRef, Inject, AfterViewInit, ViewChild, EventEmitter, Output } from '@angular/core';
import {UserService} from '../../../services/core/user.service';
import { DOCUMENT } from '@angular/common';
import { MatMenuTrigger } from '@angular/material/menu';
import { JsonService } from '../../../services/core/json.service';
import { AuthDataService } from '../../auth/services/auth.data.service';
import {first} from 'rxjs/operators';
import { filter } from 'rxjs';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit , AfterViewInit {

  @ViewChild(MatMenuTrigger) menuTrigger!: MatMenuTrigger;
  @Output() refreshMainComponet = new EventEmitter<boolean>();

  public user: any;
  public isSideBarOpen:boolean = false;
  public userDetail:any = null;
  public companyListSelection: any = [];
  currentCompanyId: any = 0;
  allowedRoutes: string[] = ['/bids', '/proposals', '/jobs' ,'/submittals','/change-orders','/tasks','/tickets','/notes','/hr', '/settings', '/customers/', '/vendors/', '/warehouses/', '/jobsites/', '/products/', '/services/', '/subcontractors/'];

  constructor(
    private currentUser: UserService,
    private element: ElementRef,
    private jsonService: JsonService,
    public authDataService: AuthDataService,
    private router: Router,
    @Inject(DOCUMENT) private document: Document) {
  }


  ngOnInit(): void {
    this.user = this.currentUser;


    this.authDataService
    .getAuthUserDetail()
    .subscribe(data => {
          localStorage.setItem("auth_detail", this.jsonService.doStringify(data.data.data))
          this.userDetail = data.data.data;

          let lastSelectedOffices = this.userDetail.selected_tenents?.map((tenant: any) => tenant?.id)

          this.userDetail.attach_tenents.forEach((element: any) => {
            this.companyListSelection.push({
              name: element.name,
              value: element.id,
              selected: lastSelectedOffices.includes(element.id),
            });
          });

          this.currentCompanyId = this.userDetail.current_tenent.id;
      })

  }


  onOfficeSettingApply() {

    let selected  = this.companyListSelection?.filter((tenant: any) => tenant.selected == true);
    let data = {
      select_tenents: selected?.map((tenant: any) => tenant?.value),
      current_tenent: this.currentCompanyId,
    }

    this.authDataService.setAuthUserOfficeSetting(data)
      .pipe(first())
      .subscribe(
        (data:any) => {
          this.authDataService
          .getAuthUserDetail()
          .subscribe(data => {
            const currentRoute = this.router.url;
            if (this.allowedRoutes.some(route => currentRoute.includes(route))) {
              this.refreshMainComponet.emit(true);
            }
            localStorage.setItem("auth_detail", this.jsonService.doStringify(data.data.data))
          })
        });

  }



  getSelectedCompanyName(): string {
    const selectedCompany = this.companyListSelection.find((tenant: any) => tenant.value == this.currentCompanyId);
    return selectedCompany ? selectedCompany.name : 'Select Office';
  }

  onMobileToggaleSideBarOpen = () =>{
    this.document.body.classList.add('sidebar-open');
  }

  onToggaleSideBar = () =>{
     if(this.isSideBarOpen){
      this.document.body.classList.remove('sidebar-pinned');
      this.isSideBarOpen = false;
    }
    else{
      this.document.body.classList.add('sidebar-pinned');
      this.isSideBarOpen = true;
    }

  }


  ngAfterViewInit(){}
}
