<div class="popup-convert-proposal">
  <div class="row m-auto popup-header-new align-items-center">
    <div class="col-md-9 align-items-center">
      <h4>{{vendorQuoteId?'Edit ':'Add '}}Vendor Quote</h4>
    </div>
    <div class="col-md-3 text-right align-items-center">
      <span class="cp close-icon" (click)="close()">
        <svg xmlns="http://www.w3.org/2000/svg" width="34" height="32" viewBox="0 0 34 32">
          <g id="Group_5952" data-name="Group 5952" transform="translate(-0.001)">
            <rect id="Rectangle_865" data-name="Rectangle 865" width="34" height="32" rx="1"
              transform="translate(0.001)" fill="#f82825" />
            <path id="Path_10" data-name="Path 10"
              d="M12.146,6.246a.685.685,0,0,1-.72.46c-1.52-.007-3.04,0-4.56,0-.162,0-.162,0-.162.163,0,1.52,0,3.04,0,4.56a.685.685,0,0,1-.46.72H5.914a.685.685,0,0,1-.46-.72c.007-1.52,0-3.04,0-4.56,0-.163,0-.164-.161-.164H.665a.632.632,0,0,1-.624-.4.622.622,0,0,1,.576-.842c.347-.005.695,0,1.042,0H5.295c.162,0,.163,0,.163-.162q0-2.321,0-4.643a.629.629,0,0,1,.4-.612A.622.622,0,0,1,6.7.618c.005.355,0,.711,0,1.066q0,1.806,0,3.612c0,.161,0,.161.164.161,1.52,0,3.04,0,4.56,0a.684.684,0,0,1,.72.46Z"
              transform="translate(8.612 16.2) rotate(-45)" fill="#f1f1f1" stroke="#f1f1f1" stroke-width="1.5" />
          </g>
        </svg>
      </span>
    </div>
  </div>

  <div class="row m-auto popup-body popup-convert-wrapper align-items-center" [formGroup]="vendorQuoteForm">

    <ng-container *ngIf="isVendorModule">
      <div class="form-row module-select-type">
        <mat-button-toggle-group name="fontStyle" aria-label="Font Style" [(value)]="moduleId"
          (change)="onModuleSelect($event)">
          <mat-button-toggle [value]="19">Job</mat-button-toggle>
          <mat-button-toggle [value]="16">Bid</mat-button-toggle>
        </mat-button-toggle-group>
      </div>
      <!--   
      <div class="col-md-6">
        <ng-container *ngIf="!showSearchInput">
          <div class="cross-icon">
            <span class="search-text"> {{this.selectedItem?.data ?? 'Search'}}</span>
            <span class="cross"  (click)="updateSearchInputFlag()">&#10005;</span>
          </div>
        </ng-container>
        <ng-container *ngIf="showSearchInput">
          <app-search-field [searchAbleDataService]="taskDataService"
            [searchAbleFunctionName]="'searchTaskReferenceNumber'" [searchAbleParams]="searchParams"
            [labelKey]="'data'" [searchPlaceHolder]="'Search reference number'" (onSelectValue)="onItemSelect($event)">
          </app-search-field>
        </ng-container>
      </div> -->

      <div class="col-md-6 mt-2">
        <label for="vendor">{{this.moduleId===19?'Job':'Bid'}} <span></span></label>
        <div class="form-group section-b-form-group">
          <!-- JOB Dropdown -->
          <app-scrollable-search-field *ngIf="this.moduleId===19" class="d-flex" id="customer"
            [dataService]="jobDataService" [dataServiceFunctionName]="'search'" [searchPlaceHolder]="'Select'"
            [labelKey]="'job_name'"
            [formDataMapper]="{'id': 'id', 'name' : 'company_name', 'contacts' : 'contacts','primary_contact': 'primary_contact'}"
            [keyNameToShowWhenSelected]="'name'" (selectedValueChange)="selectedModuleChangeValue($event)"
            [showError]="false" [sortOrder]="'desc'">
          </app-scrollable-search-field>

          <!-- Bid Dropdown -->
          <app-scrollable-search-field *ngIf="this.moduleId===16" class="d-flex" id="customer"
            [dataService]="bidDataService" [dataServiceFunctionName]="'search'" [searchPlaceHolder]="'Select'"
            [labelKey]="'name'"
            [formDataMapper]="{'id': 'id', 'name' : 'company_name', 'contacts' : 'contacts','primary_contact': 'primary_contact'}"
            [keyNameToShowWhenSelected]="'name'" (selectedValueChange)="selectedModuleChangeValue($event)"
            [showError]="false" [sortOrder]="'desc'">
          </app-scrollable-search-field>

        </div>
      </div>

    </ng-container>


    <div class="col-md-6 mt-2" *ngIf="!isVendorModule">
      <label for="vendor">Vendor <span>*</span></label>
      <div class="d-flex flx-11">
        <app-scrollable-search-field [disabled]="isVendorDisabled" class="d-flex w-100" id="customer"
          [dataService]="vendorDataService" [dataServiceFunctionName]="'search'" [searchPlaceHolder]="'Select Vendor'"
          [labelKey]="'name'"
          [formDataMapper]="{'id': 'id', 'name' : 'company_name', 'contacts' : 'contacts','primary_contact': 'primary_contact'}"
          [keyNameToShowWhenSelected]="'name'" (selectedValueChange)="selectedValueChange($event)" [showError]="false"
          [sortOrder]="'desc'" [inputVal]="vendorDetail ? vendorDetail.name : ''">
        </app-scrollable-search-field>
        <span class="plus-icon-select" (click)="createVendor()" *ngIf="!isVendorDisabled">+</span>
      </div>

      <app-validation-error [formGroup]="vendorQuoteForm" [controlName]="'vendor'"></app-validation-error>
    </div>

    <div class="col-md-6 mt-2">
      <label for="quotation_reference">Vendor Quote#<span>*</span></label>
      <input type="text" class="form-control" name="quotation_reference" id="quotation_reference"
        formControlName="quotation_reference" placeholder="Type here">
      <app-validation-error [formGroup]="vendorQuoteForm" [controlName]="'quotation_reference'"></app-validation-error>
    </div>

    <div class="col-md-6 mt-2">
      <label for="amount">Total Quote Amount <span>*</span></label>
      <div class="d-flex flx-11">
        <span class="dollar-icon">$</span>
        <input type="text" currencyMask [options]="currencyMaskOptions" id="markUp" class="form-control" name="amount"
          id="amount" formControlName="amount" placeholder="Type here">
      </div>
      <app-validation-error [formGroup]="vendorQuoteForm" [controlName]="'amount'"></app-validation-error>
    </div>

    <div class="col-md-6 mt-2">
      <label for="quantity">QTY<span>*</span></label>
      <input type="number" appPositiveValueMask class="form-control" (input)="quantityChange($event)" name="quantity"
        id="quantity" formControlName="quantity" placeholder="Type here">
      <app-validation-error [formGroup]="vendorQuoteForm" [controlName]="'quantity'"></app-validation-error>

    </div>

    <div class="col-md-6 mt-2">
      <label for="valid_till">Quote Valid Till <span>*</span></label>
      <div class="d-flex">
        <input type="text" id="valid_till" formControlName="valid_till" class="form-control mh-45"
          [matDatepicker]="open_till" placeholder="Type Here">
        <mat-datepicker-toggle class="date-picker" matIconSuffix [for]="open_till"></mat-datepicker-toggle>
        <mat-datepicker #open_till></mat-datepicker>
      </div>

      <app-validation-error [formGroup]="vendorQuoteForm" [controlName]="'valid_till'"></app-validation-error>
    </div>
    <div class="row">
      <div class="col-md-6">
        <button class="btn btn-convert-invoice" (click)="close()">Cancel</button>
      </div>
      <div class="col-md-6">
        <button class="btn btn-convert" (click)="save()">Save</button>
      </div>
    </div>
  </div>
</div>