import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {pairwise, startWith} from "rxjs/operators";

@Component({
  selector: 'app-step-preview',
  templateUrl: './step-preview.component.html',
  styleUrls: ['./step-preview.component.scss']
})
export class StepPreviewComponent implements OnInit, OnChanges {
  @Input() model: any = {};
  @Input() isEdit = false;
  @Input() form: any = {};
  @Input() step: any;
  @Output() callback = new EventEmitter<any>(true);

  public company:any = {};
  public detail:any = {};
  public contact:any = {};
  public adminInfo:any = {};
  showPassword:Boolean=false;

  constructor() { }

  public onModelValueChanged:any = {};
  ngOnChanges(changes: SimpleChanges) {
    // this.model    = changes?.model?.currentValue;
    // this.onModelValueChanged = this.model;
    // this.company  = {name: this.onModelValueChanged.company_name };
    // debugger
    // // this.detail   = this.model.model.detail;
    // debugger
    // let data = changes;
    // console.log('changes', data.currentValue);

  }
  togglePasswordVisibility2() {
    this.showPassword = !this.showPassword;
    const passwordInput = document.getElementById('password');
    if (passwordInput) {
      passwordInput.setAttribute('type', this.showPassword ? 'text' : 'password');
    }
  }

  ngOnInit(): void {


    this.model = this.model?.model;
    this.company.name = this.model?.company_name;
    this.detail = this.model?.detail;
    this.contact = this.model?.contacts[0];
    this.adminInfo =  this.model?.admin_info;


    this.form.get('step01').valueChanges
      .pipe(startWith(null), pairwise())
      .subscribe(([prev, next]: [any, any]) => {
       this.company = next?.company;
      });

    this.form.get('step02').valueChanges
      .pipe(startWith(null), pairwise())
      .subscribe(([prev, next]: [any, any]) => {
       this.detail = next?.detail;
      });

    this.form.get('step03').valueChanges
      .pipe(startWith(null), pairwise())
      .subscribe(([prev, next]: [any, any]) => {
       this.contact = next?.contact;
      });

    this.form.get('step04').valueChanges
      .pipe(startWith(null), pairwise())
      .subscribe(([prev, next]: [any, any]) => {
       this.adminInfo = next?.adminInformation;
      });

    console.log('step04',this.form.get('step04'));
  }
}
