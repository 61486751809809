<ng-container [formGroup]="companyDetailForm">
  <div class="card-body">
    <h2>Customer Detail <mat-slide-toggle formControlName="is_jobsite">Is Jobsite?</mat-slide-toggle></h2>
    <div class="form-row">
      <div class="form-group col-12">
        <label for="company_title" class="custom_label">Company Address Name/Title *</label>
        <input type="text" id="company_title" formControlName="company_title"  class="form-control" placeholder="Company Address Name/Title" name="company_title" >
        <app-validation-error [formGroup]="companyDetailForm" [controlName]="'company_title'" ></app-validation-error>

      </div>

      <div class="form-group col-12">
        <label for="company_title" class="custom_label">Company Email *</label>

        <input type="email" id="company_email" formControlName="company_email"  class="form-control" placeholder="Company email" name="company_email"    (keydown)="preventSpace($event)" >
        <app-validation-error [formGroup]="companyDetailForm" [controlName]="'company_email'" ></app-validation-error>



      </div>
    </div>

    <app-address [isRequired]="true" formControlName="main_address" (callback)="initChildForm('main_address', $event.value)" [form]="form" [model]="company_address"></app-address>

    <div class="form-row">
      <div class="form-group col-12">
        <label for="phone" class="custom_label">Phone Number *</label>
        <input type="text" id="phone" phoneMask formControlName="phone" (keypress)="omit_special_char($event)" class="form-control" placeholder="Phone Number" name="phone"  >
        <app-validation-error [formGroup]="companyDetailForm" [controlName]="'phone'" [messagePattern]="'Please enter correct phone number'"></app-validation-error>
      </div>
    </div>

    <div class="form-row">
      <div class="form-group col-12">
        <label for="secondary_phone" class="custom_label">Secondary Number </label>
        <input type="text" id="secondary_phone" phoneMask formControlName="secondary_phone" (keypress)="omit_special_char($event)"   class="form-control" placeholder="Secondary Number" name="secondary_phone">

        <app-validation-error [formGroup]="companyDetailForm" [controlName]="'secondary_phone'" [messagePhoneNumber]="'Invalid Secondary Number'" ></app-validation-error>
      </div>
    </div>

    <div class="form-row">
      <div class="form-group col-12">
        <label for="fax" class="custom_label">Fax Number</label>
        <input type="text" id="fax" formControlName="fax" (keypress)="omit_special_char($event)"  phoneMask class="form-control" placeholder="Fax Number" name="fax">
        <app-validation-error [formGroup]="companyDetailForm"  [controlName]="'fax'" [messagePhoneNumber]="'Invalid Fax Number'"></app-validation-error>
      </div>
    </div>

    <div class="form-row">
      <div class="form-group col-12">
        <label for="website" class="custom_label">Website URL</label>
        <div class="input_with_icon">
          <span class="url-prefix">{{urlPrefix}}</span>
         <input type="url" id="website" (blur)="onBlurWebsiteUrl()" formControlName="website" class="form-control" placeholder="Website URL" name="website" (keydown)="preventSpace($event)">
         </div>
        <app-validation-error [formGroup]="companyDetailForm" [messagePattern]="'Invalid Website URL'" [controlName]="'website'"></app-validation-error>

      </div>
    </div>

    <div class="form-row">
      <div class="form-group col-12">
        <label for="group" class="custom_label">Company Type(Group) *</label>
        <ng-select
          id="group"
          [items]="groups"
          placeholder="Select Group"
          [closeOnSelect]="true"
          formControlName="group"
          bindLabel="name"
        >
        </ng-select>
        <app-validation-error [formGroup]="companyDetailForm" [controlName]="'group'"></app-validation-error>
      </div>

      </div>

    <mat-accordion>
      <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
        <mat-expansion-panel-header>
          <mat-panel-title>
            Billing Address
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="my-3 d-flex justify-content-md-end">
          <button type="button" class="btn_solid_color p-2" (click)="copyCompanyAddressToBillingAddress()">Copy company address</button>
        </div>
        <app-address formControlName="billing_address" [isRequired]="true" [populateFormOnChanges]="billingAddressChangesFlag" (callback)="initChildForm('billing_address' , $event.value)" [form]="form"  [model]="billing_address"></app-address>
      </mat-expansion-panel>
      <div class="error-message" *ngIf="companyDetailForm.get('billing_address')?.invalid && companyDetailForm.get('billing_address')?.touched">
        <small style="color: red">Billing Address is Required</small>
      </div>
      <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
        <mat-expansion-panel-header>
          <mat-panel-title>
            Shipping Address
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="my-3 d-flex justify-content-md-end">
          <button type="button" class="btn_solid_color p-2" (click)="copyCompanyAddressToShippingAddress()">Copy company address</button>
        </div>
        <app-address formControlName="shipping_address" [populateFormOnChanges]="shippingAddressChangesFlag"  (callback)="initChildForm('shipping_address', $event.value)" [form]="form"  [model]="shipping_address" ></app-address>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
</ng-container>
