<div class="preview_wrapper">
    <p class="title">Company Name
        <span class="green_circle_tick">
            <img src="../../../../assets/img/white_tick.svg" alt="">
        </span>
    </p>
    <div class="row">
        <div class="col-md-6">
            <div class="wrap">
                <p class="heading">Company Name</p>
                <p class="value">{{company?.name}}</p>
            </div>
        </div>
    </div>
</div>

<div class="preview_wrapper">
    <p class="title">Company Details
        <span class="green_circle_tick">
            <img src="../../../../assets/img/white_tick.svg" alt="">
        </span>
    </p>
    <div class="row">
        <div class="col-md-6">
            <div class="wrap">
                <p class="heading">Company Address Name/Title</p>
                <p class="value">{{detail?.company_title}}</p>
            </div>
        </div>
      <div class="col-md-6">
        <div class="wrap">
          <p class="heading">Company Email</p>
          <p class="value">{{detail?.company_email}}</p>
        </div>
      </div>
        <div class="col-md-6">
            <div class="wrap">
                <p class="heading">Address 1</p>
                <p class="value">{{detail?.main_address?.address_line_1}}</p>
            </div>
        </div>
        <div class="col-md-6">
            <div class="wrap">
                <p class="heading">Address 2</p>
                <p class="value">{{detail?.main_address?.address_line_2}}</p>
            </div>
        </div>
        <div class="col-md-6">
            <div class="wrap">
                <p class="heading">City</p>
                <p class="value">{{detail?.main_address?.city}}</p>
            </div>
        </div>
        <div class="col-md-6">
            <div class="wrap">
                <p class="heading">State</p>
                <!-- <p class="value">{{detail?.main_address?.state?.state_id_selected?.name}}</p> -->
                <p class="value">{{detail?.main_address?.state_id?.name}}</p>
            </div>
        </div>
        <div class="col-md-6">
            <div class="wrap">
                <p class="heading">Zipcode</p>
                <p class="value">{{detail?.main_address?.zipcode}}</p>
            </div>
        </div>
        <div class="col-md-6">
            <div class="wrap">
                <p class="heading">Phone Number</p>
                <p class="value">{{detail?.phone}}</p>
            </div>
        </div>
        <div class="col-md-6">
            <div class="wrap">
                <p class="heading">Secondary Number </p>
                <p class="value">{{detail?.secondary_phone}}</p>
            </div>
        </div>
        <div class="col-md-6">
            <div class="wrap">
                <p class="heading">Fax Number</p>
                <p class="value">{{detail?.fax}}</p>
            </div>
        </div>
        <div class="col-md-6">
            <div class="wrap">
                <p class="heading">Website URL</p>
                <p class="value">{{detail?.website ? 'https://' + detail?.website : ''}}</p>
            </div>
        </div>
        <div class="col-md-6">
            <div class="wrap">
                <p class="heading">Company Type(Group)</p>
                <p class="value">{{detail?.group?.name}}</p>
            </div>
        </div>
    </div>

    <!-- Billing Address   -->
    <p class="title">Billing Address
      <span class="green_circle_tick">
              <img src="../../../../assets/img/white_tick.svg" alt="">
          </span>
    </p>
    <div class="row">
      <div class="col-md-6">
        <div class="wrap">
          <p class="heading">Address 1</p>
          <p class="value">{{detail?.billing_address?.address_line_1}}</p>
        </div>
      </div>
      <div class="col-md-6">
        <div class="wrap">
          <p class="heading">Address 2</p>
          <p class="value">{{detail?.billing_address?.address_line_2}}</p>
        </div>
      </div>
      <div class="col-md-6">
        <div class="wrap">
          <p class="heading">City</p>
          <p class="value">{{detail?.billing_address?.city}}</p>
        </div>
      </div>
      <div class="col-md-6">
        <div class="wrap">
          <p class="heading">State</p>
          <!-- <p class="value">{{detail?.main_address?.state?.state_id_selected?.name}}</p> -->
          <p class="value">{{detail?.billing_address?.state_id?.name}}</p>
        </div>
      </div>
      <div class="col-md-6">
        <div class="wrap">
          <p class="heading">Zipcode</p>
          <p class="value">{{detail?.billing_address?.zipcode}}</p>
        </div>
      </div>
    </div>
    <!-- Shopping Address   -->
    <p class="title">Shipping Address
      <span class="green_circle_tick">
                <img src="../../../../assets/img/white_tick.svg" alt="">
            </span>
    </p>
    <div class="row">
      <div class="col-md-6">
        <div class="wrap">
          <p class="heading">Address 1</p>
          <p class="value">{{detail?.shipping_address?.address_line_1}}</p>
        </div>
      </div>
      <div class="col-md-6">
        <div class="wrap">
          <p class="heading">Address 2</p>
          <p class="value">{{detail?.shipping_address?.address_line_2}}</p>
        </div>
      </div>
      <div class="col-md-6">
        <div class="wrap">
          <p class="heading">City</p>
          <p class="value">{{detail?.shipping_address?.city}}</p>
        </div>
      </div>
      <div class="col-md-6">
        <div class="wrap">
          <p class="heading">State</p>
          <!-- <p class="value">{{detail?.main_address?.state?.state_id_selected?.name}}</p> -->
          <p class="value">{{detail?.shipping_address?.state_id?.name}}</p>
        </div>
      </div>
      <div class="col-md-6">
        <div class="wrap">
          <p class="heading">Zipcode</p>
          <p class="value">{{detail?.shipping_address?.zipcode}}</p>
        </div>
      </div>
    </div>
</div>


<div class="preview_wrapper">
    <p class="title">Company Contact
        <span class="green_circle_tick">
            <img src="../../../../assets/img/white_tick.svg" alt="">
        </span>
    </p>
    <div class="row">
        <div class="col-md-6">
            <div class="wrap">
                <p class="heading">First Name</p>
                <p class="value">{{contact?.first_name}}</p>
            </div>
        </div>
        <div class="col-md-6">
            <div class="wrap">
                <p class="heading">Last Name</p>
                <p class="value">{{contact?.last_name}}</p>
            </div>
        </div>
        <div class="col-md-6">
            <div class="wrap">
                <p class="heading">Email </p>
                <p class="value">{{contact?.email}}</p>
            </div>
        </div>
        <div class="col-md-6">
            <div class="wrap">
                <p class="heading">Phone</p>
                <p class="value">{{contact?.phone}}</p>
            </div>
        </div>
        <div class="col-md-6">
            <div class="wrap">
                <p class="heading">Cell Phone Number</p>
                <p class="value">{{contact?.mobile}}</p>
            </div>
        </div>
        <div class="col-md-6">
            <div class="wrap">
                <p class="heading">Contact Authority</p>
                <p class="value">{{contact?.contact_authority}}</p>
            </div>
        </div>
        <div class="col-md-6">
          <div class="wrap">
            <p class="heading">Primary Contact</p>
            <p class="value" >{{contact?.is_primary ? 'Yes' : 'No'}}</p>
          </div>
        </div>
        <div class="col-md-6">
          <div class="wrap">
            <p class="heading">Portal Access</p>
            <p class="value">{{contact?.has_portal_access ? 'Yes' : 'No'}}</p>
          </div>
        </div>
      <ng-container *ngIf="contact?.has_portal_access == true">
        <div class="col-md-6">
            <div class="wrap">
                <p class="heading">Username</p>
                <p class="value">{{contact?.username}}</p>
            </div>
        </div>

        <div class="col-md-6">
          <div class="wrap position-relative">
            <p class="heading">Password</p>
            <div class="password-field">
              <span class="value" >{{ showPassword ? contact.password : '********' }}</span>
              <button type="button" class="btn btn-outline-secondary eye-icon" (click)="togglePasswordVisibility()">
                <i class="fa" [ngClass]="{'fa-eye': !showPassword, 'fa-eye-slash': showPassword}"></i>
              </button>
            </div>
          </div>
        </div>


      </ng-container>
        <div class="col-md-12">
            <div class="wrap">
                <p class="heading">Notes</p>
                <p class="value">{{contact?.notes}}</p>
            </div>
        </div>

    </div>
</div>

<div class="preview_wrapper">
    <p class="title">Admin Information
        <span class="green_circle_tick">
            <img src="../../../../assets/img/white_tick.svg" alt="">
        </span>
    </p>
    <div class="row">
      <div class="col-md-6" *ngIf="!isEdit">
        <div class="wrap">
          <p class="heading">Sales Rep</p>
          <p class="value">{{adminInfo?.sales_rep_id?.full_name}}</p>
        </div>
      </div>

      <div class="col-md-6">
        <div class="wrap">
          <p class="heading">Tax Exempt</p>
          <p class="value" >{{adminInfo?.tax_exempt ? 'Yes' : 'No'}}</p>
        </div>
      </div>
        <div class="col-md-6">
            <div class="wrap">
                <p class="heading">Default Payment Method</p>
              <!--                 Using this condition because getting different data in case of create and edit-->
              <p class="value">{{adminInfo?.payment_method?.name}}</p>
            </div>
        </div>
        <div class="col-md-6">
            <div class="wrap">
                <p class="heading">Preferred Markup</p>
                <p class="value">{{adminInfo?.markup}}</p>
            </div>
        </div>
        <div class="col-md-6">
            <div class="wrap">
                <p class="heading">Lien Period</p>
                <p class="value">{{adminInfo?.lien_period}}</p>
            </div>
        </div>
        <div class="col-md-6">
            <div class="wrap">
                <p class="heading">Source</p>
<!--                 Using this condition because getting different data in case of create and edit-->
                <p class="value">{{adminInfo?.source?.name}}</p>
            </div>
        </div>
<!--        <div class="col-md-6">-->
<!--            <div class="wrap">-->
<!--                <p class="heading">Sales Rep</p>-->
<!--                <p class="value">USA</p>-->
<!--            </div>-->
<!--        </div>-->
        <div class="col-md-6">
            <div class="wrap">
                <p class="heading">Risk Level</p>
                <p class="value">{{adminInfo?.risk_level | currency}}</p>
            </div>
        </div>



    </div>
</div>
