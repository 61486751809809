import {Directive, HostListener} from '@angular/core';
import {NgControl} from "@angular/forms";

@Directive({
  selector: '[formControlName][ZipcodeMask]'
})
export class ZipcodeMaskDirective {

  constructor(public ngcontrol: NgControl) {
  }

  @HostListener('ngModelChange', ['$event'])
  onModelChange(event: KeyboardEvent) {
    return this.omit_special_char(event);

  }

  @HostListener('keydown.backspace', ['$event'])
  keydownBackspace(event: KeyboardEvent) {
    return this.omit_special_char(event);
  }

  @HostListener('keypress', ['$event'])
  keypress(event: KeyboardEvent) {
    return this.omit_special_char(event);
  }
  @HostListener('input', ['$event'])
  @HostListener('paste', ['$event'])
  onInput(event: KeyboardEvent | ClipboardEvent): void {


    this.omit_special_char(event);
  }


  omit_special_char(event: KeyboardEvent | ClipboardEvent): boolean {
    console.log('zip --');
    const inputElement = event.target as HTMLInputElement;

    if (event instanceof ClipboardEvent) {
      const clipboardData = event.clipboardData;
      const pastedText = clipboardData?.getData('text');
      const isNumeric = /^\d+$/.test(pastedText || '');
      if (!isNumeric) {
        event.preventDefault();
        return false;
      }
      return true;
    }
    // else if (event instanceof KeyboardEvent) {
    //   const inputValue = inputElement.value;
    //   if (inputValue.length >= 10 && event.keyCode !== 8) {
    //     event.preventDefault();
    //     return false;
    //   }
    //
    //   const k = event.keyCode || event.which;
    //   return (k >= 48 && k <= 57) || k == 8 || k == 32 || k == 45;
    // }
    // Handle keyboard events
    else if (event instanceof KeyboardEvent) {
      const inputValue = inputElement.value;

      // Handle max length of 10 for ZIP code
      if (inputValue.length >= 10 && event.key !== 'Backspace' && !this.isTextSelected(inputElement)) {
        event.preventDefault();
        return false;
      }

      const key = event.key;
      const isValidKey = this.isNumericKey(key) || key === 'Backspace' || key === ' ' || key === '-';

      // Prevent invalid keys
      if (!isValidKey) {
        event.preventDefault();
        return false;
      }

      // Ensure that selected text is replaced when typing
      if (this.isTextSelected(inputElement) && key !== 'Backspace') {
        const start = inputElement.selectionStart ?? 0;
        const end = inputElement.selectionEnd ?? 0;
        const newValue = inputValue.substring(0, start) + key + inputValue.substring(end);
        this.ngcontrol.control?.setValue(newValue);
        event.preventDefault();
      }

      // Handle backspace correctly
      if (key === 'Backspace') {
        const start = inputElement.selectionStart ?? 0;
        const end = inputElement.selectionEnd ?? 0;
        if (start !== end) {
          const newValue = inputValue.substring(0, start) + inputValue.substring(end);
          this.ngcontrol.control?.setValue(newValue);
          event.preventDefault();
        }
      }
    }

    return true;
  }




  // Check if the key is numeric
  isNumericKey(key: string): boolean {
    return /^[0-9]$/.test(key);
  }

  // Check if text is selected
  isTextSelected(inputElement: HTMLInputElement): boolean {
    return inputElement.selectionStart !== inputElement.selectionEnd;
  }

}


