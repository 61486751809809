import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AttachmentsServiceService {

  constructor() { }
  isImage(file_type: any): boolean {
    const allowedExtensions = ['jpg', 'jpeg', 'png', 'gif','bmp', 'svg']; // Add more extensions if needed
    return allowedExtensions.includes(file_type.toLowerCase());
  }
}
