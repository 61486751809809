import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ToasterService } from "../../../services/core/toaster.service";
import { UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { BackendApiService } from "../../../services/backend-api.service";
import { UserService } from "../../../services/core/user.service";
import { environment } from "../../../../environments/environment";
import { DxFileUploaderComponent } from "devextreme-angular";
import { AttachmentsServiceService } from "../../../services/attachments/attachments-service.service";
import { ConfirmationPopupComponent } from '../confirmation-popup/confirmation-popup.component';
import { NgDialogAnimationService } from 'ng-dialog-animation';

@Component({
  selector: 'app-attachment',
  templateUrl: './attachment.component.html',
  styleUrls: ['./attachment.component.scss']
})
export class AttachmentComponent implements OnInit {
  // dropZone variables
  isDropZoneActive = false;
  imageSource = '';
  textVisible = true;
  progressVisible = false;
  progressValue = 0;
  isValidFileSelected = false;
  // end dropZone variables
  @Input() isRequired: boolean = false;
  @Input() model: any = {};
  @Input() module_id: any;
  @Input() fileTypes: any = ['.jpg', '.jpeg', '.gif', '.png'];
  @Input() multipleUpload: boolean = false;
  @Input() dropZoneId: any = 'defaultFilesDropZone';
  @Input() multipleImagesOnEdit: any = [];
  @Input() imageOnEdit: any = '';
  @Output() callback = new EventEmitter<any>(true);
  @Output() onDelete = new EventEmitter<any>(true);
  @Input() allowAllFileTypes: boolean = false;
  public staric: string = '*';
  public sectionForm: UntypedFormGroup = this.serviceForm();
  public authorization: any;
  showDropZone: boolean = true;
  fileType: any = 'image';
  newUploadedFile: any = null;
  //@ts-ignore
  @ViewChild('fileUploaderRef', { static: false }) fileUploader: DxFileUploaderComponent;

  @Input() multipleUploadData = [] as any;
  constructor(
    public toasterService: ToasterService,
    public attachmentsServiceService: AttachmentsServiceService,
    public backendServiceApi: BackendApiService,
    private currentUser: UserService,
    public dialog: NgDialogAnimationService,
  ) { }

  ngOnInit(): void {
    this.updateStaricString();
    this.initFormControlValues(this.model);
    this.authorization = this.currentUser.getToken();
    this.showDropZone = !(this.imageOnEdit || this.multipleImagesOnEdit.length > 0);
    this.handleEditImage();

    // this.textVisible = !!this.model?.image_file
  }

  initFormControlValues(value: any) {
    if (value) {
      this.sectionForm = this.serviceForm(value);
      // this.callback.emit(this.sectionForm);
      this.imageSource = value.url;
    }
    return this.sectionForm;
  }

  serviceForm(value: any = null) {
    return new UntypedFormGroup({
      file: new UntypedFormControl(value ? value : null, this.isRequired ? [Validators.required] : []),
      file_new: new UntypedFormControl(value ? value : null, this.isRequired ? [Validators.required] : []),
    });
  }
  handleEditImage() {
    if (this.imageOnEdit) {
      this.fileType = this.isImage(this.imageOnEdit?.file_type) ? 'image' : 'other';
      const imageSource = this.imageOnEdit?.url ? this.imageOnEdit?.url : this.imageOnEdit?.attachment;
      this.imageSource = imageSource;
      const attachment = {
        id: this.imageOnEdit?.id,
        attachment: imageSource,
        attachment_field: this.imageOnEdit?.attachment_field,
        file_type: this.imageOnEdit?.file_type,
      };
      const data = {
        attach_file: attachment,
        attachment_id: this.imageOnEdit?.id
      }
      this.callback.emit({ attachment: data });
    }
  }

  updateStaricString = () => {
    if (this.isRequired) {
      this.staric = '*';
      return;
    }
    this.staric = '';
  }

  onDropZoneEnter(e: any) {
    if (e.dropZoneElement.id === 'dropzone-external') { this.isDropZoneActive = true; }
  }

  onDropZoneLeave(e: any) {
    if (e.dropZoneElement.id === 'dropzone-external') { this.isDropZoneActive = false; }
  }

  onUploaded(e: any) {
    const response = JSON.parse(e.request.response);
    this.sectionForm.get('file_new')?.setValue(null);
    this.toasterService.showSuccessMessage(response?.data?.message);
    const attachment_id = response?.data?.attachment_id;

    this.newUploadedFile = response?.data;

    // this.textVisible = false;
    this.progressVisible = false;
    this.progressValue = 0
    this.showDropZone = false;
    this.imageSource = response?.data?.attach_file?.attachment
    const attachmentObjForMultipleFilesProcessing = {
      attach_file: response?.data?.attach_file,
      attachment_id: response?.data?.attachment_id,
    }
    this.multipleUploadData.push(attachmentObjForMultipleFilesProcessing);
    this.callback.emit({ attachment: this.multipleUpload ? this.multipleUploadData : response?.data });
  }

  onProgress(e: any) {
    this.progressValue = e.bytesLoaded / e.bytesTotal * 100;
  }

  onUploadStarted(e: any) {

    this.imageSource = '';
    this.progressVisible = true;
    const data = {
      module_id: this.module_id,
      attachment_field: 'test-uploads',
      attachment: this.sectionForm.get('file_new')?.value,
    }
    return data;
  }
  uploadError(e: any) {
    this.progressVisible = false;
    this.toasterService.showErrorMessage('something went wrong please try again');
  }
  onValueChanged(e: any) {

  }
  onFileOptionChanged(e: any) {
    if (e?.value[0]) {

      const files = e.value;
      let validationFailedFlag = false;

      files.forEach((file: any) => {
        // Check the file's validity
        const isValid = this.isFileValid(file);

        if (!isValid) {
          this.toasterService.showWarning(`Invalid file selected: ${file.name}`);
          validationFailedFlag = true;
        }
      });

      if (validationFailedFlag) {
        return true;
      }
      const type = e.value[0].type.split('/', 1)[0];
      const extension = '.' + e.value[0].type.split('/')[1];


      // if(!this.fileTypes.includes(extension.toLowerCase())) {
      //   this.toasterService.showWarning('Please select valid file type');
      //   return true;
      // }

      this.fileType = type;
      const file = e.value[0];
      this.sectionForm.get('file_new')?.setValue(file);
      this.isDropZoneActive = false;
      if (type === 'image') {
        const fileReader = new FileReader();
        fileReader.onload = () => {
          this.isDropZoneActive = false;
          this.imageSource = fileReader.result as string;
          this.sectionForm.get('file')?.setValue(this.imageSource);
        };
        fileReader.readAsDataURL(file);
      }
    }
    return true;
  }


  isFileValid(file: any): boolean {
    const validExtensions = this.fileTypes;
    const maxFileSize = 5000000; // 5MB

    const fileExtension = file.name.substring(file.name.lastIndexOf('.')).toLowerCase();
    const isValidExtension = validExtensions.includes(fileExtension);
    const isValidSize = file.size <= maxFileSize;

    return isValidExtension && isValidSize;
  }

  // getAttachmentData = (e: any) => {
  //   const data = {
  //     module_id: 20,
  //     attachment_field: 'test-uploads',
  //     attachment: this.sectionForm.get('file')?.value,
  //   }
  //   return data;
  // }

  customHeader = () => {
    return {
      // 'Content-Type':'application/json; charset=utf-8',
      'Accept': 'application/json',
      'Authorization': `Bearer ${this.currentUser.getToken()}`,
    }
  }
  customData() {
    return {
      module_id: this?.module_id,
      attachment_field: 'image',
      attachment: this.sectionForm.get('file_new')?.value,
    }
  }

  SaveImage(e: any, fileUploader: any) {

  }

  validationStatusChange(e: any) {
  }

  onFilesUploaded(event: any) {
  }

  protected readonly environment = environment;
  protected readonly Array = Array;

  removeImage(image: any, type: number) { // type = 1 => single image, // type=2 => multiple image
    const dialogRef = this.dialog.open(ConfirmationPopupComponent, {
      width: '500px',
      height: '300px',
      data: {
        title: 'Remove Attachement',
        message: 'Are you sure you want to remove selected item?',
        description: ''
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        if (type == 1) {
          this.deleteAttachmentById(this.newUploadedFile ? this.newUploadedFile?.attachment_id : this.imageOnEdit?.id);
        } else {
          this.deleteAttachmentById(image?.attachable_id)
        }
      }
    });

  }

  removeImageFileMultipleCase(fileData: any) {
    const dialogRef = this.dialog.open(ConfirmationPopupComponent, {
      width: '550px',
      height: '300px',
      data: {
        title: `Remove ${fileData?.attach_file?.original_name ||fileData?.attach_file?.attachment?.split('/')?.pop()}`,
        message: 'Are you sure you want to remove selected item?',
        description: ''
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.deleteAttachmentById(fileData.attachment_id)
      }
    });
  }

  deleteAttachmentById(id: any) {
    this.imageSource = '';
    this.showDropZone = true;
    this.onDelete.emit(true);

    this.backendServiceApi.deleteApi(`attachments/${id}`)
      .subscribe((response: any) => {
        this.toasterService.showSuccessMessage(response?.data.message);

        if (this.multipleUpload) {
          this.multipleUploadData = this.multipleUploadData.filter((data: any) => data.attachment_id != id);
          this.callback.emit({ attachment: this.multipleUploadData });
        }

      })
  }

  isImage(file_type: any): boolean {
    return this.attachmentsServiceService.isImage(file_type);
  }
}
