import {environment} from "../../../environments/environment";

function createUrl(actionName: string): string {
  return `${environment.apiBaseUrl}${environment.apiUrl}${environment.apiVersion}${actionName}`;
}

export const SharedApiResources = {
  countries:  createUrl('countries'),
  states:  createUrl('states'),
  stateTaxes: createUrl('state-taxes'),
  taxClass:  createUrl('tax-class'),
  currencies:  createUrl('currencies'),
  paymentMethods:  createUrl('payment-methods'),
  scopes:  createUrl('scopes'),
  stages:  createUrl('stages'),
  sources:  createUrl('sources'),
  bidTypes:  createUrl('bid-types'),
  moduleStatues:  createUrl('status'),
  moduleTemplate:  createUrl('template/module?search='),
  getModuleTemplateBySlug:  createUrl('template/module?slug='),
  getModuleStatusById:  createUrl('status-setting'),
};

export const CurrencyMaskOptions = {
  prefix: '',
  thousands: ',',
  decimal: '.',
  precision:'2',
  align:'left'
}
