export function getBackgroundColor(backgroundColor: string): string {
  if (!backgroundColor) {
    return '';
  }

  let r, g, b;

  if (backgroundColor.startsWith('#')) {
    // Hex format
    const hex = backgroundColor.replace('#', '');
    r = parseInt(hex.substring(0, 2), 16);
    g = parseInt(hex.substring(2, 4), 16);
    b = parseInt(hex.substring(4, 6), 16);
  } else if (backgroundColor.startsWith('rgb')) {
    // RGB format
    const rgbValues = backgroundColor.match(/\d+/g);
    if (rgbValues && (rgbValues.length === 3 || rgbValues.length === 5)) {
      r = parseInt(rgbValues[0]);
      g = parseInt(rgbValues[1]);
      b = parseInt(rgbValues[2]);
    } else {
      return '';
    }
  } else {
    return ''; // Return empty string if format is unrecognized
  }

  // Calculate the relative luminance (using sRGB values)
  const luminance = 0.2126 * r + 0.7152 * g + 0.0722 * b;

  // Return black for light colors and white for dark colors
  return luminance > 220 ? '#808080' : '#FFFFFF';
}

export function getRandomDarkColor() {
  const r = Math.floor(Math.random() * 256);
  const g = Math.floor(Math.random() * 256);
  const b = Math.floor(Math.random() * 256);
  return `rgb(${r}, ${g}, ${b})`;
}


export let colorList= [
  "#FF6633", "#B34D4D", "#80B300", "#809900", "#6680B3", "#66991A", "#E6331A", "#4D8000", 
  "#B33300", "#4D8066", "#809980", "#FF3380", "#4D80CC", "#9900B3", "#E64D66", "#4DB380", 
  "#FF4D4D", "#6666FF", "#B3CC33", "#8A2BE2", "#DC143C", "#8B4513", "#6495ED", "#006400", 
  "#8B008B", "#DA70D6", "#7B68EE", "#4682B4", "#D2691E", "#FF8C00", "#20B2AA", "#008B8B", 
  "#A52A2A", "#5F9EA0", "#9932CC", "#B22222", "#556B2F", "#B8860B", "#A0522D", "#808000", 
  "#DB7093", "#FF4500", "#A0522D", "#9932CC", "#FF6347", "#8B0000", "#8FBC8F", "#483D8B"
];