<app-sidebar-navigation></app-sidebar-navigation>
<!--site header begins-->
<app-header (refreshMainComponet)="refreshMainComponent($event)"></app-header>
<router-outlet *ngIf="showMainComponent"></router-outlet>

<div *ngIf="loading" class="loader-container">
  <div class="loader"></div>
  Loading, please wait...
</div>


