import {Component, Inject, OnInit} from '@angular/core';
import {UntypedFormGroup} from "@angular/forms";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {BackendApiService} from "../../../services/backend-api.service";
import {ToasterService} from "../../../services/core/toaster.service";
import {NgDialogAnimationService} from "ng-dialog-animation";
import {DialogService} from "../../../services/core/dialog.service";
import {AddTaskComponent} from "../../sale/task/add-task/add-task.component";
import {ConfirmationPopupComponent} from "../../shared/confirmation-popup/confirmation-popup.component";
import {
  ObserverParticipantPopupComponent
} from "../../sale/task/add-task/add-checklist/observer-participant-popup/observer-participant-popup.component";
import {AddEditTicketComponent} from "../add-edit-ticket/add-edit-ticket.component";
import {TicketUserPopupComponent} from "../add-edit-ticket/ticket-user-popup/ticket-user-popup.component";
import {ExportPdfSharedComponent} from "../../shared/export-pdf/export-pdf.component";
import { TicketCommunicationService } from '../ticket-communication.service';
import { getBackgroundColor } from 'src/app/utils/utils.function';
import { environment } from 'src/environments/environment';
import { HelperService } from 'src/app/services/helper.service';

@Component({
  selector: 'app-view-ticket-detail',
  templateUrl: './view-ticket-detail.component.html',
  styleUrls: ['./view-ticket-detail.component.scss']
})
export class ViewTicketDetailComponent implements OnInit {

  public ticketID: any;
  model: any = '';
  public TICKET_MODULE_ID: number = 7;
  stages: any = [];
  statuses: any = [];
  public CONST_RESPONSIBLE_PERSON_TYPE: string = 'responsible_person';
  public CONST_CREATED_BY_TYPE: string = 'created_by';
  public CONST_OBSERVER_TYPE: string = 'observer';
  public CONST_PARTICIPANT_TYPE: string = 'participant';
  TYPE_USER: any = 'Users';
  TYPE_VENDORS: any = 'Vendors';
  TYPE_CUSTOMERS: any = 'Customers';
  TYPE_SUBCONTRACTORS: any = 'Subcontractors';
  loadListingOnClose = false;

  // Permissions
  createPermission = 'DONOTAPPLY';
  editPermission = 'DONOTAPPLY';
  deletePermission = 'DONOTAPPLY';
  changeStatusPermission = 'DONOTAPPLY';
  changePriorityPermission = 'DONOTAPPLY';
  exportPDFPermission = 'DONOTAPPLY';

  public sectionForm: UntypedFormGroup = new UntypedFormGroup({

  });
  constructor(
    public dialogRef: MatDialogRef<ViewTicketDetailComponent>,
    public backendApiService: BackendApiService,
    public toasterService: ToasterService,
    public dialog: NgDialogAnimationService,
    public dialogService: DialogService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private ticketCommunicationService:TicketCommunicationService,
    private helperService:HelperService
  ) {
    this.ticketID = this.data?.id;
    this.setPermissions(this.data?.permissions);
  }

  ngOnInit(): void {
    this.getTicketByID();
    this.getTicketStatuses();
    // this.getStagesOfModule(this.TASK_MODULE_ID);
  }

  getTicketByID() {
    this.backendApiService.getApi(`ticket/${this.ticketID}`)
      .subscribe((response: any)=> {
        console.log('response',response);
        this.model = response.data;
        // this.getStagesOfModule(this.model?.module?.id);
      })
  }

  getStagesOfModule(id:any) {
    this.backendApiService.getApi('stages?module_id='+id)
      .subscribe((response: any) => {
        this.stages = response.data;
      })
  }

  editTask() {
    this.close();
    this.dialogService.onOpenFromRightDialog(AddEditTicketComponent,{
      isEdit:true,
      model:this.model,
      id: this.model?.id,
    },1300);
    this.dialogService.afterClose()?.subscribe((result: any) => {
      if(result) {
        this.ticketCommunicationService.setTicketListUpdate(true)
      }
    });
  }

  onConfirmation(): void {
    const dialogRef = this.dialog.open(ConfirmationPopupComponent, {
      width: '500px',
      height: '300px',
      data: {}
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.onDelete();
      }
    });
  }

  onDelete() {
    this.backendApiService.deleteApi(`ticket/${this.model?.id}`)
      .subscribe((response: any) => {
        this.toasterService.showSuccessMessage(response.data.message);
        this.close(true)
      })
  }

  close = (data: any = null) => {
    this.dialogRef.close(data);
  }

  createTicket() {
    this.close();
    this.dialogService.onOpenFromRightDialog(AddEditTicketComponent,{
      moduleID: this.model?.module?.id  ,
      referenceID: this.model?.reference?.id,
    },1300);
    this.dialogService.afterClose()?.subscribe((result: any) => {
      if(result) {
      }
    });
  }

  addStage(stage: any) {
    this.backendApiService.postApi('ticket/update-stage/'+this.model?.id,{stage_id:stage?.id})
      .subscribe((response: any) => {
        this.toasterService.showSuccessMessage(response?.data?.message);
        this.getTicketByID();
      })
  }

  openUserPopup(event: any,type: any) {
    const position = event.target.getBoundingClientRect();
    const dialogRef = this.dialog.open(TicketUserPopupComponent,{
      width: '400px',
      height: '400px',
      // position: {top:`${position.top+ 30}px`, left: `${position.left - 360}px`},
      data: {
        type: type,
      }
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      console.log('result',result);
      if(result) {
        if(result.type == this.CONST_RESPONSIBLE_PERSON_TYPE) {
          this.addResponsiblePerson({user:result?.user,type:result?.selectedTab})
        }  else if(result.type == this.CONST_OBSERVER_TYPE) {
          this.addObserver({user:result?.user,type:result?.selectedTab})
        } else if(result.type == this.CONST_PARTICIPANT_TYPE) {
          this.addParticipant({user:result?.user,type:result?.selectedTab})
        }
      }
    })
  }

  addResponsiblePerson(user: any){
    const users = [];
    users.push(user?.user.id);
    const usersList = this.model?.responsible_persons;
    const alreadyExistCheck = usersList.find((x:any) => x.id == user?.user.id);
    if (!alreadyExistCheck){
      this.backendApiService.postApi('ticket/add/responsible/person/'+this.model?.id,{user_id:users,type:user?.type})
        .subscribe((response: any) => {
          this.toasterService.showSuccessMessage(response?.data?.message);
          this.model?.responsible_persons?.push(response?.data?.data);
        })
    }
  }

  addObserver(user: any) {
    const users = [];
    users.push(user?.user.id);
    const usersList = this.model?.observers;
    const alreadyExistCheck = usersList.find((x:any) => x.id == user?.user.id);
    if (!alreadyExistCheck) {
      this.backendApiService.postApi('ticket/add/observer/' + this.model?.id, {user_id: users, type: user?.type})
        .subscribe((response: any) => {
          this.toasterService.showSuccessMessage(response?.data?.message);
          this.model?.observers?.push(response?.data?.data);
        })
    }
  }

  addParticipant(user: any) {
    const users = [];
    users.push(user?.user.id);
    const usersList = this.model?.participants;
    const alreadyExistCheck = usersList.find((x:any) => x.id == user?.user.id);
    if (!alreadyExistCheck) {
      this.backendApiService.postApi('ticket/add/participant/' + this.model?.id, {user_id: users, type: user?.type})
        .subscribe((response: any) => {
          this.toasterService.showSuccessMessage(response?.data?.message);
          this.model?.participants?.push(response?.data?.data);
        })
    }
  }

  deleteAssociation(user: any) {
    this.backendApiService.deleteApi('ticket/association/'+user?.assoc_id)
      .subscribe((response: any) => {
        this.toasterService.showSuccessMessage(response.data?.message);
        this.getTicketByID();
      })
  }

  getChipIconUsingType(type: any) {
    let image = '';
    switch (type) {
      case (this.TYPE_USER) :
        image = '../../../../assets/img/general_icons/user-dark.png';
        break;
      case (this.TYPE_CUSTOMERS):
        image = '../../../../assets/img/general_icons/custo-dark.png';
        break;
      case (this.TYPE_VENDORS):
        image = '../../../../assets/img/general_icons/vendor-dark.png';
        break;
      case (this.TYPE_SUBCONTRACTORS):
        image = '../../../../assets/img/general_icons/subcon-dark.png';
        break;
      default:
        image = '../../../../assets/img/general_icons/admin-dark.png';
    }
    return image;
  }

  generatePDF() {
    const dialogRef = this.dialog.open(ExportPdfSharedComponent, {
      width: '30%',
      // height: '80%',
      data: {
        moduleName:'ticket',
        moduleSlug: 'ticket',
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.helperService.getTemplates(environment.apiBaseUrl+'/ticket/generate-pdf',this.model.id,result);
        this.toasterService.showWarning('PDF export not available.');
      }
    });
  }
  getTicketStatuses() {
    this.backendApiService.getApi('statuses/ticket')
      .subscribe((response: any) => {
        this.statuses = response?.data;
      })
  }
  onChangeStatus(data: any) {
    this.backendApiService.postApi('ticket/change-status/'+this.model?.id,{status:data?.id})
      .subscribe((response: any) => {
        this.toasterService.showSuccessMessage(response?.data.message);
        this.getTicketByID();
        this.ticketCommunicationService.setTicketListUpdate(true)
      })
  }

  getBackgroundColor(color:string){
    return getBackgroundColor(color)
   }
  setPermissions(permissions: any){
    if (permissions){
      if (permissions?.createPermission){
        this.createPermission = permissions?.createPermission;
      }
      if (permissions?.editPermission){
        this.editPermission = permissions?.editPermission;
      }

      if (permissions?.deletePermission){
        this.deletePermission = permissions?.deletePermission;
      }
      if (permissions?.changeStatusPermission){
        this.changeStatusPermission = permissions?.changeStatusPermission;
      }
      if (permissions?.changePriorityPermission){
        this.changePriorityPermission = permissions?.changePriorityPermission;
      }
      if (permissions?.exportPDFPermission){
        this.exportPDFPermission = permissions?.exportPDFPermission;
      }

    }

  }
}
