import {
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  AbstractControl,
  UntypedFormControl,
  UntypedFormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import { first, Subscription } from 'rxjs';
import { CustomerService } from 'src/app/modules/customer/customer.service';
import { pairwise, startWith } from 'rxjs/operators';
import { NotOnlySpaces } from '../../../shared/CustomValidators/CustomNotOnlySpacesValidator';
import { EmailValidator } from '../../../shared/CustomValidators/CustomInvalidEmailValidator';
import { PhoneNumberValidator } from '../../../shared/CustomValidators/CustomInvalidPhoneNumberValidator';
@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ContactComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => ContactComponent),
      multi: true,
    },
  ],
})
export class ContactComponent implements OnInit {
  @Input() isEdit: boolean = false;
  @Input() model: any = {};
  @Input() customerDetail: any = {};
  @Output() callback = new EventEmitter<any>(true);
  @Output() onInitForm = new EventEmitter<any>(true);
  @Input() form: any = {};
  @Input() markScrollAbleSearchSelectTouched = false;
  public authorities: any[] = [];
  public customers: any[] = [];
  public preSelectedCustomer: any = null;
  showPassword: Boolean = false;
  // @ts-ignore
  public sectionFormSubscription: Subscription;

  public companyContactForm: UntypedFormGroup = new UntypedFormGroup({
    customer: new UntypedFormControl(null, [
      Validators.required,
      NotOnlySpaces(),
    ]),
    first_name: new UntypedFormControl(null, [
      Validators.required,
      NotOnlySpaces(),
    ]),
    last_name: new UntypedFormControl(null),
    email: new UntypedFormControl(null, [
      Validators.required,
      EmailValidator(),
    ]),
    phone: new UntypedFormControl(null, [
      Validators.required,
      PhoneNumberValidator(),
    ]),
    mobile: new UntypedFormControl(null, [PhoneNumberValidator()]),
    contact_authority: new UntypedFormControl(null),
    username: new UntypedFormControl(null),
    password: new UntypedFormControl(null),
    notes: new UntypedFormControl(null),
    has_portal_access: new UntypedFormControl(0),
    is_primary: new UntypedFormControl(0),
  });

  constructor(public customerService: CustomerService) {}

  ngOnChanges(changes: SimpleChanges) {
    if (
      Object.keys(changes).length === 1 &&
      changes['markScrollAbleSearchSelectTouched']
    ) {
      return;
    }
    this.model = changes?.model?.currentValue;
    this.setFormValueFromModel();
  }

  ngOnInit(): void {
    this.getAuthorities();
    this.getCustomers();
    if (this.isEdit) {
      this.companyContactForm.removeControl('password');
    }
    this.initSubscriptions();
  }
  togglePasswordVisibility() {
    this.showPassword = !this.showPassword;
    const passwordInput = document.getElementById('password');
    if (passwordInput) {
      passwordInput.setAttribute(
        'type',
        this.showPassword ? 'text' : 'password'
      );
    }
  }
  getAuthorities = () => {
    this.customerService
      .getContactTypes()
      .pipe(first())
      .subscribe((data: any) => {
        this.authorities = data?.data?.map((authority: any) => {
          return { id: authority.id, name: authority.name };
        });
      });
  };

  preventSpace(event: KeyboardEvent) {
    if (event.keyCode === 32) {
      event.preventDefault();
    }
  }

  getCustomers = () => {
    const customer = this.model?.customer ? this.model?.customer  :  this.customerDetail ;
    this.companyContactForm.get('customer')?.setValue({
      id: customer?.id,
      name: customer?.company_name,
    });
    // this.customerService.getCustomers()
    //   .pipe(first())
    //   .subscribe(
    //     (data:any) => {
    //       this.customers = data?.data?.map((customer:any)=>{
    //         return {id: customer.id, name: customer.company_name};
    //       });
    //       const customerId = this.model?.customer ? this.model?.customer?.id  :  this.customerDetail?.id ;
    //       const customer =  this.customers.find((x:any) => x.id == customerId);
    //       this.companyContactForm.get('customer')?.setValue(customer);
    //     });
  };

  selectedValueChange(event: any) {
    this.companyContactForm.get('customer')?.setValue(event);
  }

  setFormValueFromModel = () => {
    this.companyContactForm.get('first_name')?.setValue(this.model?.first_name);
    this.companyContactForm.get('last_name')?.setValue(this.model?.last_name);
    this.companyContactForm.get('email')?.setValue(this.model?.email);
    this.companyContactForm.get('phone')?.setValue(this.model?.phone);
    this.companyContactForm.get('mobile')?.setValue(this.model?.mobile);
    this.companyContactForm
      .get('contact_authority')
      ?.setValue(this.model?.contact_authority);
    this.companyContactForm.get('notes')?.setValue(this.model?.notes);
    this.companyContactForm.get('username')?.setValue(this.model?.username);
    this.companyContactForm
      .get('has_portal_access')
      ?.setValue(this.model?.has_portal_access ? true : false);
    this.companyContactForm
      .get('is_primary')
      ?.setValue(this.model?.is_primary ? true : false);
    this.companyContactForm.updateValueAndValidity({
      onlySelf: false,
      emitEvent: true,
    });
    this.onInitForm.emit(this.companyContactForm);
    //this.callback.emit({model:this.model, isEdit: this.isEdit});
  };
  isDisabledPrimaryToggle(){
    return this.model?.is_primary == 1;
  }
  isDisabledPrimaryToggleMessage(){
    return this.model?.is_primary?
      'Making primary any other contact will automatically set this contact to not primary.':'';
  }
  public onTouched: () => void = () => {};

  writeValue(val: any): void {
    val && this.companyContactForm.setValue(val, { emitEvent: false });
  }

  registerOnChange(fn: any): void {
    this.companyContactForm.valueChanges.subscribe(fn);
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    isDisabled
      ? this.companyContactForm.disable()
      : this.companyContactForm.enable();
  }

  validate(c: AbstractControl): ValidationErrors | null {
    return this.companyContactForm.valid
      ? null
      : {
          invalidForm: {
            valid: false,
            message: 'Company contact fields are invalid',
          },
        };
  }

  initSubscriptions() {
    if (this.form) {
      this.sectionFormSubscription = this.form.valueChanges
        .pipe(startWith(null), pairwise())
        .subscribe(([prev, next]: [any, any]) => {
          if (this.form.touched) {
            this.touchFormControls();
          }
        });
    }
  }
  touchFormControls() {
    Object.keys(this.companyContactForm.controls).forEach((key) => {
      this.companyContactForm.get(key)?.markAsTouched();
    });
  }
  ngOnDestroy() {
    // prevent memory leak when component destroyed
    this.sectionFormSubscription?.unsubscribe();
  }
}
