<div class="proposals_tab tabs_main_wrap">

  <div class="row">

    <div class="col-md-10">

      <div class="search_wrapper">
        <button type="button" (click)="onSearchTextChange()"><img src="../../../../assets/img/search_icon.svg" alt=""
            class="search_icon"></button>
        <input type="search" [(ngModel)]="search" [ngModelOptions]="{ standalone: true }"
          (keyup.enter)="onSearchTextChange()" (ngModelChange)="onSearch()"  name="" id="" class="no_boarder_field" placeholder="Search Ticket">
      </div>
    </div>

    <div class="col-md-2">
      <!-- <div class="buttons">
        <button style="background:#1EA8FE !important;" class="btn list-btn color- ml-2"
          [appPermission]="'TICKET-CREATE'" matTooltip="Create Ticket" (click)="createTicket()">
          <mat-icon>add</mat-icon>
        </button>
      </div> -->
    </div>
  </div>




  <!-- <div class="row filter_row">
    <div class="col-md-6">
      <div class="search_wrapper">
        <button type="submit"><img src="../../../../assets/img/search_icon.svg" alt="" class="search_icon"></button>
        <input type="search" name="" id="" class="no_boarder_field" placeholder="Tickets">
      </div>
    </div>
    <div class="col-md-6 flex_adjustment">
      <div class="dropdown generic_dropdown_flter_orange">
        <button type="button" class="btn dropdown-toggle" data-bs-toggle="dropdown">
          <img src="../../../../assets/img/general_icons/filter_icon.png" alt="">
          <span class="text">Filter</span>
        </button>
        <div class="dropdown-menu">
          <div class="row">
            <div class="col-sm-4">
              <label for=""><img
                src="../../../../../../../assets/img/inventory/products/detail/parts/vendorCard/vendorName.svg" alt="">
                Vendor Name</label>
              <input type="text" class="form-control">
            </div>
            <div class="col-sm-4">
              <label for=""><img
                src="../../../../../../../assets/img/inventory/products/detail/parts/vendorCard/vendorMultiplier.svg"
                alt=""> Vendor Multiplier</label>
              <input type="text" class="form-control">
            </div>
            <div class="col-sm-4">
              <label for=""><img
                src="../../../../../../../assets/img/inventory/products/detail/parts/vendorCard/unitPrice.svg" alt="">Unit
                Price</label>
              <input type="text" class="form-control">
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <div class="controls_wrapper">
                <button type="button" class="btn btn_apply" data-bs-toggle="dropdown">Apply</button>
                <button type="button" class="btn btn_cancel" data-bs-toggle="dropdown">Cancel</button>
              </div>
            </div>
          </div>


        </div>
      </div>
    </div>
  </div> -->

  <ng-container *ngIf="ticketList?.length > 0">
    <ng-container *ngFor="let ticket of ticketList">
      <div class="row_data_wrapper " [ngStyle]="{
        'border': '1px solid ' + (ticket?.status_label?.color ||'#808080') ,
        'border-left-width': '10px'}" style="cursor: pointer">

      <div class='wrap-container'  (click)="openTicketDetail(ticket.id)" [appPermission]="viewDetailPermission">
        <div class="wrap w-15" >
          <span class="key">
            <img src="../../../../assets/img/general_icons/job_name_icon.png" alt="">
            <span> #</span>
          </span>
          <span class="value">{{ticket?.code}}</span>
        </div>


        <div class="wrap w-15">
          <span class="key">
            <img src="../../../../../../assets/img/general_icons/invoice_date_icon.png" alt="date icon">
            <span>Ticket Name</span>
          </span>
          <span class="value">{{ticket?.name}}</span>
        </div>

        <div class="wrap w-15" *ngIf="ticket?.reference?.name">
          <span class="key">
            <img src="../../../../assets/img/general_icons/job_name_icon.png" alt="">
            <span>Job Name</span>
          </span>
          <span class="value">{{ticket?.reference?.name}}</span>
        </div>

        <!--        <div class="wrap">-->
        <!--        <span class="key">-->
        <!--          <img src="../../../../assets/img/general_icons/money_icon.png" alt="">-->
        <!--        <span>Task Subject</span>-->
        <!--        </span>-->
        <!--          <span class="value"></span>-->
        <!--        </div>-->



        <div class="wrap w-15">
          <span class="key"> <img src="../../../../assets/img/general_icons/switch_icon.png" alt="">
          <span>Scope(s)</span>
          </span>
          <app-show-data-point-with-tooltip [items]="ticket?.scopes" key="name"></app-show-data-point-with-tooltip>

<!--          <span class="value" matTooltip="{{concatString(ticket?.scopes,'name' )}}">{{ticket?.scopes?.length ? ticket?.scopes[0]?.name : '&#45;&#45;'}} <span class="symbol" *ngIf="ticket?.scopes?.length > 1">+</span><span class="symbol_box" *ngIf="ticket?.scopes?.length > 1">{{ticket?.scopes?.length - 1}}</span></span>-->
       </div>

       <div class="wrap w-15">
        <span class="key">
          <img src="../../../../assets/img/general_icons/type.png" alt="">
          <span>Type(Category)</span>
        </span>
        <span class="value">{{ticket?.ticket_type?.name||'--'}}</span>
      </div>

        <!-- <div class="wrap">
          <span class="key"> <img
              src="../../../../../../../assets/img/inventory/products/detail/parts/vendorCard/unitPrice.svg" alt="">
            <span>Created By</span>
          </span>
          <span class="value">{{ticket?.created_by?.full_name}}</span>
        </div> -->

        <div class="wrap w-10">
          <span class="key">  <img src="../../../../../assets/img/general_icons/color-fire.png" alt="fire icon">
            <span>Priority</span>
          </span>
          <span class="value">{{ticket?.priority_label ||'--'}}</span>
        </div>





      <div class="wrap w-17">
        <span class="key"> <img src="../../../../assets/img/general_icons/switch_icon.png" alt="">
        <span>Assigned to</span>
        </span>
        <span class="value" matTooltip="{{concatString(ticket?.responsible_persons,'full_name')}}">{{ticket?.responsible_persons?.length ? ticket?.responsible_persons[0]?.full_name : '--'}} <span class="symbol" *ngIf="ticket?.responsible_persons?.length > 1">+</span><span class="symbol_box" *ngIf="ticket?.responsible_persons?.length > 1">{{ticket?.responsible_persons?.length - 1}}</span></span>
      </div>
        <div class="wrap w-10">
          <span class="key">
            <img src="../../../../assets/img/general_icons/status_icon_2.png" alt="">
            <span>Status</span>
          </span>

          <span class="btn_status" [ngStyle]="{'color': ticket?.status_label?.color}" > <span class="circle"  [ngStyle]="{'background-color': ticket?.status_label?.color}"></span> {{ticket?.status_label?.name | truncate: 8 }} </span>

        </div>
        <!-- <div class="wrap">
          <span class="key">
            <img src="../../../../../../assets/img/general_icons/invoice_date_icon.png" alt="date icon">
            <span>Creation Date</span>
          </span>
          <span class="value">{{ticket?.created_on}}</span>
        </div> -->
        <!-- <div class="wrap">
          <span class="key">
            <img src="../../../../../../assets/img/general_icons/invoice_date_icon.png" alt="date icon">
            <span>Due Date</span>
          </span>
          <span class="value">{{ticket?.due_date||'--'}}</span>
        </div> -->
      </div>
        <div class="wrap w-2">
          <a [matMenuTriggerFor]="menu" class="cursor-pointer">
            <mat-icon matTooltip="Menu" matTooltipPosition="above">more_vert</mat-icon>
          </a>
          <mat-menu #menu="matMenu">
            <!--            <button class="import_btn" mat-menu-item (click)="openTicketCommentDetail(ticket.id)" >-->
            <!--              <span class="float_left f-14"> Comments</span>-->
            <!--            </button>-->

            <button class="import_btn" mat-menu-item (click)="openTicketDetail(ticket.id)" [appPermission]="viewDetailPermission">
              <span class="float_left f-14">Go To Detail</span>
            </button>

            <button class="import_btn" mat-menu-item (click)="generatePDF(ticket.id)" [appPermission]="exportPDFPermission">
              <span class="float_left f-14"  >Export as PDF</span>
            </button>


          </mat-menu>
        </div>
      </div>
    </ng-container>
  </ng-container>

</div>


<div class="load-more-wrapper">
  <div class="load-more-btn" *ngIf="showLoadMoreOption" (click)="loadMoreData()">Load More</div>
</div>


