<div class="files-wrapper" >
  <div class="row">
    <div class="col-md-3 mt-2" *ngFor="let fileData of filesData">
      <ng-container *ngIf="isImage(fileData.file_type)">
<!--        <div class="mr-3">-->
<!--          <a href="javascript:void(0);" style="display: block;" class="remove-attachment" ><img src="../../../../assets/img/cross_icon.svg"></a>-->
          <a class="custom-checkbox" target="_blank" href="{{fileData.url}}">
            <img id="dropzone-image--" [src]="fileData.url"  alt="" style="height: 125px;"/>
          </a>
          <p>{{fileData?.original_name}}</p>
<!--        </div>-->
      </ng-container>

      <ng-container *ngIf="!isImage(fileData.file_type)">
        <div >
<!--          <a href="javascript:void(0);" style="display: block;" class="remove-attachment" ><img src="../../../../assets/img/cross_icon.svg"></a>-->
          <div class="file-type">
            <a class="custom-checkbox" target="_blank" href="{{fileData.url}}">
            <img src="../../../../assets/img/general_icons/icon-file.png" style="height: 125px;"/>
            </a>
            <p>{{fileData?.original_name}}</p>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</div>
