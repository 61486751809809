<ng-container [formGroup]="companyContactForm">
  <div class="card-body">
    <h2>Company Contact <mat-slide-toggle
      [disabled]="isDisabledPrimaryToggle()" [matTooltip]="isDisabledPrimaryToggleMessage()"  formControlName="is_primary">Primary Contact?</mat-slide-toggle></h2>
    <div class="form-row">
      <div class="form-group col-12">
        <label for="first_name" class="custom_label">First Name *</label>
        <input type="text" id="first_name" formControlName="first_name" class="form-control" placeholder="First Name" >
        <app-validation-error [formGroup]="companyContactForm" [controlName]="'first_name'"></app-validation-error>
      </div>
    </div>

    <div class="form-row">
      <div class="form-group col-12">
        <label for="last_name" class="custom_label">Last Name</label>
        <input type="text" id="last_name" formControlName="last_name" class="form-control" placeholder="Last Name" name="last_name" >
      </div>
    </div>

    <div class="form-row">
      <div class="form-group col-12">
        <label for="email" class="custom_label">Email *</label>

        <input type="email" id="email" formControlName="email" class="form-control" placeholder="Email" name="email" (keydown)="preventSpace($event)" >
        <app-validation-error [formGroup]="companyContactForm" [controlName]="'email'"></app-validation-error>

      </div>
    </div>

    <div class="form-row">
      <div class="form-group col-12">
        <label for="contact_phone" class="custom_label">Phone Number *</label>
        <input type="tel" id="contact_phone" formControlName="phone" (keypress)="omit_special_char($event)"  phoneMask class="form-control" placeholder="Phone Number" name="phone">
        <app-validation-error [formGroup]="companyContactForm" [controlName]="'phone'" [messagePattern]="'Please enter correct phone number'"></app-validation-error>
      </div>
    </div>

    <div class="form-row">
      <div class="form-group col-12">
        <label for="cell_phone_number" class="custom_label">Cell Phone Number </label>
        <input type="tel" id="cell_phone_number" formControlName="mobile" phoneMask class="form-control" (keypress)="omit_special_char($event)"  placeholder="Cell Phone Number" name="mobile">
        <app-validation-error [formGroup]="companyContactForm" [controlName]="'mobile'" [messagePattern]="'Please enter correct phone number'"></app-validation-error>
      </div>
    </div>


    <div class="form-row">
      <div class="form-group col-12">
        <label for="contact_authority" class="custom_label">Contact Authority</label>
        <input type="text" id="contact_authority" formControlName="contact_authority" class="form-control" placeholder="Contact Authority" name="contact_authority" >
      </div>
    </div>

    <mat-slide-toggle formControlName="has_portal_access">Portal Access?</mat-slide-toggle>

    <ng-container *ngIf="companyContactForm.get('has_portal_access')?.value">
      <div class="form-row">
        <div class="form-group col-12">
          <label for="username" class="custom_label">Username</label>
          <input type="text" id="username" formControlName="username" class="form-control" placeholder="Username" name="username" >
        </div>
      </div>

      <div class="form-row">
        <div class="form-group col-12 position-relative">
          <label for="password" class="custom_label">Password</label>
          <input type="password" id="password" formControlName="password" class="form-control" placeholder="Password" name="password">
          <button type="button" class="btn btn-outline-secondary eye-icon" (click)="togglePasswordVisibility()">
            <i class="fa" [ngClass]="{'fa-eye': !showPassword, 'fa-eye-slash': showPassword}"></i>
          </button>
        </div>
      </div>
    </ng-container>
    <div class="form-row">
      <div class="form-group col-12">
        <label for="notes" class="custom_label">Notes</label>
        <textarea id="notes" class="form-control" formControlName="notes" placeholder="Notes" name="notes" ></textarea>
      </div>
    </div>
  </div>
</ng-container>
