import { Component, OnInit } from '@angular/core';
import {RightPanelHideShowService} from "../../../services/right-panel-hide-show.service";
import { DataTableConfigurationService } from 'src/app/services/core/datatableConfiguration.service';
import { AuthDataService } from '../../auth/services/auth.data.service';
import { JsonService } from 'src/app/services/core/json.service';

@Component({
  selector: 'app-main-layout',
  templateUrl: './main-layout.component.html',
  styleUrls: ['./main-layout.component.scss']
})
export class MainLayoutComponent implements OnInit {

  public showMainComponent: boolean = true;
  loading = false;

  constructor(
    public dataTableConfigurationService: DataTableConfigurationService,
    public authDataService: AuthDataService,
    public jsonService: JsonService,
    public panelHideShow: RightPanelHideShowService
  ) { }

  ngOnInit(): void {
    this.panelHideShow.hideRightSidePanel();

    const moduleNames = ['Customer', 'Contact', 'Staff', 'Product', 'Service', 'Subcontractor', 'Vendor', 'Warehouse', 'jobsite', 'note', 'Bid', 'Change_Order', 'Job', 'Proposal', 'Submittal', 'Task', 'TaskTemplate', 'Ticket'];

    moduleNames.forEach((row:any) => {
      this.dataTableConfigurationService.resetInitDataTableConfiguration(row)
    });

    this.dataTableConfigurationService
    .getDataTableConfiguration()
    .subscribe(data => {
        data.data.forEach((item:any) => {
          localStorage.setItem(item.module_name, item.configurations)
        });
    })

    // this.authDataService
    // .getAuthUserDetail()
    // .subscribe(data => {
    //       localStorage.setItem("auth_detail", this.jsonService.doStringify(data.data.data))
    // })

  }

  refreshMainComponent(data: boolean) {
   if(data) {
    this.loading = true;
    this.showMainComponent = false;
    setTimeout(() => {
      this.showMainComponent = true;
      this.loading = false;
    }, 5000);
   }
  }

}
