import {Directive, HostListener} from '@angular/core';
import {NgControl} from "@angular/forms";

@Directive({
  selector: '[formControlName][appCityValueMask]'
})
export class CityValueMaskDirective {

  constructor(public ngControl: NgControl) { }

  @HostListener('keypress', ['$event'])
  keypress(event: any) {
    const k = event.keyCode || event.which;
    console.log('event');
    const isLetter = (k >= 65 && k <= 90) || (k >= 97 && k <= 122) || k === 32; // Allow letters and spaces only
    if (!isLetter) {
      event.preventDefault();
    }
  }

  @HostListener('paste', ['$event'])
  handlePaste(event: ClipboardEvent) {
    const clipboardData = event.clipboardData || (window as any).clipboardData;
    const pastedText = clipboardData.getData('text');
    if (/\d/.test(pastedText)) {
      event.preventDefault();
    }
  }

  @HostListener('drop', ['$event'])
  handleDrop(event: DragEvent) {
    if (event.dataTransfer?.getData('text')) {
      if (/\d/.test(event.dataTransfer.getData('text'))) {
        event.preventDefault();
        event.stopPropagation();
      }
    }
  }

}
