<div class="product_update_popup_wrapper">
  <div class="header">
    <h4 mat-dialog-title>
      Add Vendor
    </h4>
    <div class="cross_wrap">
      <a href="javascript:void(0);" (click)="close()"  >
        <img src="../../../../assets/img/cross_icon.svg" alt="" class="icon">
      </a>
    </div>
  </div>

  <div mat-dialog-content [formGroup]="sectionForm">
    <div class="form-row" >
      <div class="form-group col-12">
        <label for="name" class="custom_label"> Vendor Name *</label>
        <input type="text"  id="name" formControlName="name" class="form-control" placeholder="Manufacture Name" >
        <app-validation-error [formGroup]="sectionForm" [controlName]="'name'"></app-validation-error>
      </div>
    </div>
  </div>
  <div mat-dialog-actions align="center">
    <button mat-raised-button (click)="onConfirm()" >Add</button>
  </div>
</div>
