<mat-accordion [formGroup]="optionsForm">


  <mat-expansion-panel>
    <mat-expansion-panel-header>
      <mat-panel-title>
        Display Options
      </mat-panel-title>
      <!-- <mat-panel-description>
        Description
      </mat-panel-description> -->

    </mat-expansion-panel-header>
    <ng-container [formGroupName]="'displayOptions'">
      <div class="col-12 mb-2">
        <mat-checkbox class="example-margin" [formControlName]="'product'" (change)="onChangeDisplayOptionsFn($event)">Product</mat-checkbox>
      </div>
      <div class="col-12 mb-2">
        <mat-checkbox class="example-margin"[formControlName]="'service'"  (change)="onChangeDisplayOptionsFn($event)">Service</mat-checkbox>
      </div>
      <div class="col-12 mb-2">
        <mat-checkbox class="example-margin" [formControlName]="'labour'"  (change)="onChangeDisplayOptionsFn($event)">Labor</mat-checkbox>
      </div>
      <div class="col-12 mb-2">
        <mat-checkbox class="example-margin" [formControlName]="'shipping'"  (change)="onChangeDisplayOptionsFn($event)">Shipping</mat-checkbox>
      </div>
<!--      <div class="col-12 mb-2">-->
<!--        <mat-checkbox class="example-margin" [formControlName]="'unitSellingPrice'"  (change)="onChangeDisplayOptionsFn($event)">Unit Selling Price</mat-checkbox>-->
<!--      </div>-->
      <div class="col-12 mb-2">
        <mat-checkbox class="example-margin" [formControlName]="'signature'"  (change)="onChangeDisplayOptionsFn($event)">Signature</mat-checkbox>
      </div>
<!--      <div class="col-12 mb-2">-->
<!--        <mat-checkbox class="example-margin" [formControlName]="'totalPrice'"  (change)="onChangeDisplayOptionsFn($event)">Total Price</mat-checkbox>-->
<!--      </div>-->
      <div class="col-12 mb-2">
        <mat-checkbox class="example-margin" [formControlName]="'providedServices'"  (change)="onChangeDisplayOptionsFn($event)">Provided Services</mat-checkbox>
      </div>
<!--      <div class="col-12 mb-2">-->
<!--        <mat-checkbox class="example-margin" [formControlName]="'tax'"  (change)="onChangeDisplayOptionsFn($event)">Tax</mat-checkbox>-->
<!--      </div>-->
      <div class="col-12 mb-2">
        <mat-checkbox class="example-margin" [formControlName]="'qualifyingNotes'"  (change)="onChangeDisplayOptionsFn($event)">Qualifying Notes</mat-checkbox>
      </div>
      <div class="col-12 mb-2">
        <mat-checkbox class="example-margin" [formControlName]="'scopeNotes'"  (change)="onChangeDisplayOptionsFn($event)" >Scope Notes</mat-checkbox>
      </div>


      <div class="col-12 mb-2">
        <mat-checkbox class="example-margin" [formControlName]="'approvedQuantity'"  (change)="onChangeDisplayOptionsFn($event)" >Approved Quantity</mat-checkbox>
      </div>
      <div class="col-12 mb-2">
        <mat-checkbox class="example-margin" [formControlName]="'rejectedQuantity'"  (change)="onChangeDisplayOptionsFn($event)" >Rejected Quantity</mat-checkbox>
      </div>
      <div class="col-12 mb-2">
        <mat-checkbox class="example-margin" [formControlName]="'productSpecificationSheet'"  (change)="onChangeDisplayOptionsFn($event)" >Product Specification Sheet</mat-checkbox>
      </div>
      <div class="col-12 mb-2">
        <mat-checkbox class="example-margin" [formControlName]="'productWarrantySheet'"  (change)="onChangeDisplayOptionsFn($event)" >Product Warranty Sheet</mat-checkbox>
      </div>
      <div class="col-12 mb-2">
        <mat-checkbox class="example-margin" [formControlName]="'serviceSpecificationSheet'"  (change)="onChangeDisplayOptionsFn($event)" >Service Specification Sheet</mat-checkbox>
      </div>
      <div class="col-12 mb-2">
        <mat-checkbox class="example-margin" [formControlName]="'serviceWarrantySheet'"  (change)="onChangeDisplayOptionsFn($event)" >Service Warranty Sheet</mat-checkbox>
      </div>
    </ng-container>
  </mat-expansion-panel>


</mat-accordion>
