import {throwError as observableThrowError,  Observable, BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { tap, catchError } from 'rxjs/operators';
import {JsonService} from "../../services/core/json.service";
import {SharedApiResources} from "./shared.constants";

@Injectable({
  providedIn: 'root'
})
export class SharedDataService {
  private baseURL;
  userPermissions : any

   currentUserRole = new BehaviorSubject<string>('');

  constructor(
    private http: HttpClient,
    private jsonService: JsonService,
  ) {
    this.baseURL = SharedApiResources;
  }

  getCountries(): Observable<any> {
    const endPoint = `${this.baseURL.countries}`;
    return this.http.get<any>(endPoint)
                    .pipe(tap(data => (this.jsonService.doStringify(data))) , catchError(this.errorHandlerMessage));
  }

  getStates(countryID: any = null): Observable<any> {
    const endPoint = `${this.baseURL.states}`;
    return this.http.post<any>(endPoint, {country_id: countryID})
      .pipe(tap(data => (this.jsonService.doStringify(data))) , catchError(this.errorHandlerMessage));
  }

  getStateTax(state: any): Observable<any> {
    const endPoint = `${this.baseURL.stateTaxes}/${state}/get-tax`;
    return this.http.get<any>(endPoint)
      .pipe(tap(data => (this.jsonService.doStringify(data))) , catchError(this.errorHandlerMessage));
  }

  updateStateTax(state: any, tax: any = null): Observable<any> {
    const endPoint = `${this.baseURL.stateTaxes}`;
    return this.http.post<any>(endPoint, { state, tax })
      .pipe(tap(data => (this.jsonService.doStringify(data))) , catchError(this.errorHandlerMessage));
  }

  getTaxClass(): Observable<any> {
    const endPoint = `${this.baseURL.taxClass}`;
    return this.http.get<any>(endPoint)
      .pipe(tap(data => (this.jsonService.doStringify(data))) , catchError(this.errorHandlerMessage));
  }

  getCurrencies(): Observable<any> {
    const endPoint = `${this.baseURL.currencies}`;
    return this.http.get<any>(endPoint)
      .pipe(tap(data => (this.jsonService.doStringify(data))) , catchError(this.errorHandlerMessage));
  }

  getPaymentMethods(): Observable<any> {
    const endPoint = `${this.baseURL.paymentMethods}`;
    return this.http.get<any>(endPoint)
      .pipe(tap(data => (this.jsonService.doStringify(data))) , catchError(this.errorHandlerMessage));
  }

  getScopes(search: string =''): Observable<any> {
    const endPoint = `${this.baseURL.scopes}?search=${search}`;
    return this.http.get<any>(endPoint)
      .pipe(tap(data => (this.jsonService.doStringify(data))) , catchError(this.errorHandlerMessage));
  }

  getStages(): Observable<any> {
    const endPoint = `${this.baseURL.stages}`;
    return this.http.get<any>(endPoint)
      .pipe(tap(data => (this.jsonService.doStringify(data))) , catchError(this.errorHandlerMessage));
  }

  getSources(): Observable<any> {
    const endPoint = `${this.baseURL.sources}`;
    return this.http.get<any>(endPoint)
      .pipe(tap(data => (this.jsonService.doStringify(data))) , catchError(this.errorHandlerMessage));
  }

  getBidTypes(): Observable<any> {
    const endPoint = `${this.baseURL.bidTypes}`;
    return this.http.post<any>(endPoint, {})
      .pipe(tap(data => (this.jsonService.doStringify(data))) , catchError(this.errorHandlerMessage));
  }

  getStatusesByModule(module:string): Observable<any> {
    const endPoint = `${this.baseURL.moduleStatues}/${module}`;
    return this.http.get<any>(endPoint)
      .pipe(tap(data => (this.jsonService.doStringify(data))) , catchError(this.errorHandlerMessage));
  }
  getTemplateByModule(module:string): Observable<any> {
    const endPoint = `${this.baseURL.moduleTemplate}${module}`;
    return this.http.get<any>(endPoint)
      .pipe(tap(data => (this.jsonService.doStringify(data))) , catchError(this.errorHandlerMessage));
  }
  getTemplateByModuleSlug(slug:string): Observable<any> {
    const endPoint = `${this.baseURL.getModuleTemplateBySlug}${slug}`;
    return this.http.get<any>(endPoint)
      .pipe(tap(data => (this.jsonService.doStringify(data))) , catchError(this.errorHandlerMessage));
  }
  getPermissions() {
    const getUserPermissions = localStorage.getItem('_userPermissions')
     this.userPermissions = getUserPermissions?.split(',')
     return this.userPermissions;
  }

  getIsCurrentUserSuperAdmin() {
    const currentUserIsSuperAdmin: any = localStorage.getItem('_currentUserIsSuperAdmin');
     return currentUserIsSuperAdmin == '1' || currentUserIsSuperAdmin == 1 ? true : false;
  }

  getCurrentUserRole() {
     return localStorage.getItem('_currentUserRoleSlug');
  }

  getStagesByModule(module:string): Observable<any> {
    const endPoint = `${this.baseURL.stages}?module_id=${module}`;
    return this.http.get<any>(endPoint)
      .pipe(tap(data => (this.jsonService.doStringify(data))) , catchError(this.errorHandlerMessage));
  }
  getStatusByModule(id:string): Observable<any> {
    const endPoint = `${this.baseURL.getModuleStatusById}?module_id=${id}`;
    return this.http.get<any>(endPoint)
      .pipe(tap(data => (this.jsonService.doStringify(data))) , catchError(this.errorHandlerMessage));
  }


  errorHandler(error: HttpErrorResponse) {
    return observableThrowError(error.error || 'Server Error');
  }

  errorHandlerMessage(error: HttpErrorResponse) {
    return observableThrowError(error.error || 'Server Error');
  }
}
