import { AbstractControl, ValidatorFn } from '@angular/forms';

export function EmailValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {

    // const emailPattern = /^[a-z0-9._%+-]+@[a-z0-9.-.+._]+\.[a-z]{2,}(?<!\.[a-z]{4,})$/i;     //Need this code to track changes
    // const emailPattern = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/i;
    const emailPattern =  /^[a-z0-9áéíóúÁÉÍÓÚ!#$%&'*+/=?^_`{|}~-]+([._%+-]?[a-z0-9áéíóúÁÉÍÓÚ!#$%&'*+/=?^_`{|}~-]+)*@[a-z0-9áéíóúÁÉÍÓÚ!#$%&'*+/=?^_`{|}~-]+(\.[a-z0-9áéíóúÁÉÍÓÚ!#$%&'*+/=?^_`{|}~-]+)*\.[a-z]{2,}$/i
    ;
    const value = control.value;


    if (!value || value.trim() === '') {
      return null;
    }


    const isValid = emailPattern.test(value);
    return isValid ? null : { 'invalidEmail': { value } };
  };
}
