import {Component, Inject, OnInit} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from "@angular/forms";
import {ToasterService} from "../../../../../../../services/core/toaster.service";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {first} from "rxjs/operators";
import {BackendApiService} from "../../../../../../../services/backend-api.service";

@Component({
  selector: 'app-quick-vendor',
  templateUrl: './quick-vendor.component.html',
  styleUrls: ['./quick-vendor.component.scss']
})
export class QuickVendorComponent implements OnInit {
  public sectionForm!: UntypedFormGroup;
  constructor(
    public toasterService: ToasterService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<QuickVendorComponent>,
    public backendApiService:BackendApiService,
  ) { }

  ngOnInit(): void {
    this.initForm();
  }
  initForm(){
    this.sectionForm = new UntypedFormGroup({
      name: new UntypedFormControl('' , [Validators.required]),
    });
  }

  getFormData() {
    const form = this.sectionForm?.value;
    return {
      name: form?.name,
      email: `${form?.name}@gmail.com`
    }
  }

  onConfirm = () => {
    if (this.sectionForm.invalid) {
      this.touchFormControls();
      return;
    }

    this.backendApiService.postApi('vendors/quick-create', this.getFormData())
      .subscribe((response: any) => {
        if(response) {
          this.close(response);
        }
      })
  }
  touchFormControls(){
    Object.keys(this.sectionForm.controls).forEach(key => {
      this.sectionForm.get(key)?.markAsTouched();
    });
  }
  close = (data: any = null) => {
    this.dialogRef.close(data);
  }

}
