<div class="widget-container" [formGroup]="sectionForm">
  <ng-container *ngIf="showDropZone || multipleUpload">
    <div style="height: auto;" class="dropzone-external" id="{{dropZoneId}}" [ngClass]="isDropZoneActive ? ['dx-theme-accent-as-border-color', 'dropzone-active'] : ['dx-theme-border-color']">
      <div id="dropzone-text" class="" *ngIf="textVisible">
        <img  src="../../../../../../assets/img/inventory/products/create/file.svg">
        <br>
        <span>Drag & Drop Or Browse File</span>
      </div>
      <dx-progress-bar
        #uploadProgress
        [min]="0"
        [max]="100"
        width="30%"
        [showStatus]="false"
        [visible]="progressVisible"
        [value]="progressValue"
      ></dx-progress-bar>
    </div>
    <app-validation-error [formGroup]="sectionForm" [controlName]="'file'"></app-validation-error>
    <dx-file-uploader
        #fileUploaderRef
        (onDropZoneEnter)="onDropZoneEnter($event)"
        uploadUrl="{{environment.apiBaseUrl}}{{environment.apiUrl}}{{environment.apiVersion}}attachments"
        (onDropZoneLeave)="onDropZoneLeave($event)"
        (onOptionChanged)="onFileOptionChanged($event)"
        (onValueChanged)="onValueChanged($event)"
        (onProgress)="onProgress($event)"
        (onUploadError)="uploadError($event)"
        (onUploadStarted)="onUploadStarted($event)"
        (onUploaded)="onUploaded($event)"
        (onValueChanged)="SaveImage($event, fileUploaderRef)"
        (validationStatusChange)="validationStatusChange($event)"
        [invalidFileExtensionMessage]="'File type is not allowed'"
        isValid:isValidFileSelected
        [multiple]="false"
        [uploadHeaders]="customHeader()"
        [uploadCustomData]="customData()"
        [visible]="false"
        [name]="'attachment'"
        dialogTrigger="#{{dropZoneId}}"
        dropZone="#{{dropZoneId}}"
        uploadMode="instantly"
        [allowedFileExtensions]="fileTypes"
        accept="*"
      ></dx-file-uploader>
  </ng-container>
</div>

<div style="margin-top: 15px;height: auto;" class="dropzone-external-preview" [ngClass]=" isDropZoneActive ? ['dx-theme-accent-as-border-color', 'dropzone-active']: ['dx-theme-border-color']" *ngIf="!showDropZone || (multipleUpload && multipleUploadData.length)">
<!--  <img id="dropzone-image" [src]="imageSource" *ngIf="imageSource" alt="" />-->
  <ng-container *ngIf="!multipleUpload">
    <ng-container *ngIf="fileType == 'image'">
      <div >
        <a href="javascript:void(0);" class="remove-attachment position-absolute" (click)="removeImage('',1)"><img src="../../../../assets/img/cross_icon.svg"></a>
        <img id="dropzone-image--" [src]="imageSource" *ngIf="imageSource" alt="" style="height: 125px;"/>
        
      </div>
    </ng-container>

    <ng-container *ngIf="fileType != 'image'">
      <div >
        <a href="javascript:void(0);" class="remove-attachment position-absolute" (click)="removeImage('',1)"><img src="../../../../assets/img/cross_icon.svg"></a>
        <div class="file-type">
          <img src="../../../../assets/img/general_icons/icon-file.png" style="height: 125px;"/>
        </div>
      </div>
    </ng-container>
  </ng-container>

  <span class="d-flex" *ngIf="multipleUpload">
    <div  *ngFor="let fileData of multipleUploadData">
      <ng-container *ngIf="isImage(fileData.attach_file.file_type)">
        <div class="mr-3">
          <a href="javascript:void(0);" style="display: block;" class="remove-attachment" (click)="removeImageFileMultipleCase(fileData)"><img src="../../../../assets/img/cross_icon.svg"></a>
          <img id="dropzone-image--" [src]="fileData.attach_file.attachment || fileData.attach_file.url" *ngIf="fileData.attach_file.attachment || fileData.attach_file.url" alt="" style="height: 125px;"/>
        </div>
        <p>{{fileData?.attach_file?.original_name || fileData?.attach_file?.attachment?.split('/')?.pop()}}</p>
      </ng-container>
  
      <ng-container *ngIf="!isImage(fileData.attach_file.file_type)">
        <div >
          <a href="javascript:void(0);" style="display: block;" class="remove-attachment" (click)="removeImageFileMultipleCase(fileData)"><img src="../../../../assets/img/cross_icon.svg"></a>
          <div class="file-type">
            <img src="../../../../assets/img/general_icons/icon-file.png" style="height: 125px;"/>
          </div>
          <p>{{fileData?.attach_file?.original_name || fileData?.attach_file?.attachment?.split('/')?.pop()}}</p>
        </div>
      </ng-container>
    </div>
  </span>
  


  <ng-container *ngIf="Array.isArray(multipleImagesOnEdit) && !multipleUpload">
    <!-- Display multiple images if imageSource is an array -->
    <div *ngFor="let image of multipleImagesOnEdit">
      <div >
        <a href="javascript:void(0);" class="remove-attachment position-absolute" (click)="removeImage(image,1)"><img src="../../../../assets/img/cross_icon.svg"></a>
        <img [src]="image?.url" id="dropzone-image-" alt="" style="height: 125px;">
      </div>

    </div>
  </ng-container>
</div>

<!--http://devapi.prosuitesystems.com//api/v1/attachments-->

<!--https://js.devexpress.com/Demos/NetCore/FileUploader/Uploads-->
