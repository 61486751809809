import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { EChartOption, ECharts } from 'echarts';
import { chartOptions, defaultChartOptions } from '../echart-constant/pie-chart-options';

@Component({
  selector: 'app-echart',
  templateUrl: './echart.component.html',
  styleUrls: ['./echart.component.scss']
})
export class EchartComponent {
  private chartsInstance!: ECharts
  @Output() chartInstance = new EventEmitter<ECharts>() // #26

  initOpts = {
    renderer: 'canvas',
  }

  @Input() chartOption: EChartOption=defaultChartOptions 

  onChartInit(ec: any) {
    this.chartsInstance = ec
    this.chartInstance.emit(this.chartsInstance)
    this.resizeChart()
  }

  resizeChart() {
    if (this.chartsInstance) {
      this.chartsInstance.resize()
    }
  }
}