import { NoteCommunicationService } from './../note-communication.service';
import { STEPPER_GLOBAL_OPTIONS } from "@angular/cdk/stepper";
import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { NgDialogAnimationService } from "ng-dialog-animation";
import { BackendApiService } from 'src/app/services/backend-api.service';
import { DialogService } from "../../../services/core/dialog.service";
import { ToasterService } from "../../../services/core/toaster.service";
import { TaskDataService } from '../../sale/task/task.data.service';
import { NoteObserverParticipantPopupComponent } from "../note-observer-participant-popup/note-observer-participant-popup.component";
import { NoteDataService } from "../note.data.service";
import { AppConstants } from 'src/app/constants/app.constants';

@Component({
  selector: 'app-create-note',
  templateUrl: './create-note.component.html',
  styleUrls: ['./create-note.component.scss'],
  providers: [
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: {showError: true},
    },
  ]
})
export class CreateNoteComponent implements OnInit {
  moduleID!: number;
  referenceID!: number;
  noteId!:number
  isNoteModule:boolean = true;
  modulesList: any = [];
  showSearchInput: boolean = true;
  isEdit:boolean = false;

  searchParams = {
    type: '',
    module_id: '',
    search: ''
  };

  selectedItem: any = {};
  selectedMentionedUsers: any  = [];
  responsibleUsersArray: any = [];
  model:any

  public NOTE_MODULE_ID: number = 23;
  public CONST_CREATED_BY: boolean = false;
  public CONST_OBSERVER: boolean = false;
  public CONST_PARTICIPANT: boolean = false;
  public CONST_RESPONSIBLE_PERSON_TYPE: string = 'responsible_person';
  public CONST_CREATED_BY_TYPE: string = 'created_by';
  public CONST_OBSERVER_TYPE: string = 'observer';
  public CONST_PARTICIPANT_TYPE: string = 'participant';

  public CONST_FLAG: boolean = false;

  createdByArray: any;
  observerArray: any = [];
  participantArray: any = [];
  stages: any = [];
  selectedStage: any = {};
  mentions: any = [];
  attachmentsArr = [];
  attachmentsToAdd = [];
  allowedFileTypes: any = [];


  noteForm: UntypedFormGroup = new UntypedFormGroup({
    name: new UntypedFormControl(null,[Validators.required]),
    description: new UntypedFormControl(null),
    flag: new UntypedFormControl(false),
    module_id: new UntypedFormControl(null),
    reference_id: new UntypedFormControl(null),
    status:new UntypedFormControl(null)
  })


  constructor(
    public dialogService: DialogService,
    public dialog: NgDialogAnimationService,
    private cd: ChangeDetectorRef,
    public toasterService: ToasterService,
    public noteDataService:NoteDataService,
    public taskDataService: TaskDataService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<CreateNoteComponent>,
    public backendApiService: BackendApiService,
    private noteCommunicationService:NoteCommunicationService
  ) {
    if(Object.keys(data).length >0){
      this.moduleID = this.data?.moduleID;
      this.referenceID = this.data?.referenceID;
      this.isNoteModule = this.data?.isNoteModule;
      this.isEdit = this.data?.isEdit;
      this.model = this.data?.model;
      this.noteId = this.data?.model?.id;
    }
    this.allowedFileTypes =  AppConstants.documentFileTypes;
  }

  ngOnInit(): void {
    this.noteForm.get('module_id')?.setValue(this.moduleID);
    this.noteForm.get('reference_id')?.setValue(this.referenceID);
    this.getModules();
    if (this.moduleID){
      this.getStagesOfModule(this.moduleID);
    }
  }

  ngAfterViewInit() {
    if(this.isEdit) {
      this.setDataOnEdit();
    }
  }


  setDataOnEdit() {
    this.selectedMentionedUsers = this.model.mentions.map((mention: any) =>  {
      return {
        user: mention,
        type:'Users'
      }
    });
    const filesData = this.model.relations.attach_files.map((file: any)=>{
      return {
        attach_file: file,
        attachment_id: file.id
      }
    });
    if (this.model?.priority == 1){
      this.changeFlag();
    }
    this.attachmentsArr = filesData;
    this.noteForm.patchValue({
      name: this.model?.name,
      description: this.model?.description,
      flag: this.model?.flag==1?true:false,
      code:this.model?.code,
      module_id: this.moduleID,
      reference_id: this.referenceID,
      status:this.model.status
    })

    this.CONST_FLAG=this.model?.flag==1?true:false
    this.createdByArray = this.model?.created_by;

  }

  save(){
    const form = this.noteForm.value;
    let isInvalid = false;

    if (!form.reference_id){
      isInvalid = true;
      this.toasterService.showWarning('Name', 'Name field is required.');
    }

    if (!form.module_id){
      isInvalid = true;
      this.toasterService.showWarning('Module', 'Module Field is Required.');
    }

    if (isInvalid){
      return true;
    }

    const service = this.noteId ? this.backendApiService.patchApi(`notes/${this.model?.id}`,this.getFormData()) :
    this.backendApiService.postApi('notes/create',this.getFormData())

     service.subscribe((resposne: any) => {
       this.toasterService.showSuccessMessage(resposne.data?.message);
       this.close(true);
     })


    return true

  }

  close = (data: any = null) => {
    this.noteCommunicationService.setNoteUpdate(true)
    this.dialogRef.close(data);
  }

  changeFlag(event: any=''){
    this.CONST_FLAG = !this.CONST_FLAG;
  }

  getFileID(event: any) {
    console.log('event file id',event);
    const data  = event?.attachment;
    if(Array.isArray(data)){
      this.attachmentsArr = data as any;
      this.attachmentsToAdd = this.attachmentsArr.map((file: any) => file.attachment_id) as any;
    }
  }


  remove(index: any,dataArray: any) {
    dataArray.splice(index,1);
  }


  hideShowCreatedBy() {
    this.CONST_CREATED_BY = !this.CONST_CREATED_BY;
  }

  hideShowObserver() {
    this.CONST_OBSERVER = !this.CONST_OBSERVER;
  }

  hideShowParticipant() {
    this.CONST_PARTICIPANT = !this.CONST_PARTICIPANT;
  }

  openUsersPopup(event: any,type: any) {
    const position = event.target.getBoundingClientRect();
    const dialogRef = this.dialog.open(NoteObserverParticipantPopupComponent,{
      width: '400px',
      height: '400px',
      position: {top:`${position.top}px`, left: `${position.left}px`},
      data: {
        type: type,
      }
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      console.log('result',result);
      if(result) {
        if(result.type == this.CONST_RESPONSIBLE_PERSON_TYPE) {
          this.responsibleUsersArray.push(result.user);
        } else if(result.type == this.CONST_CREATED_BY_TYPE) {
          this.createdByArray = result.user
        } else if(result.type == this.CONST_OBSERVER_TYPE) {
          this.observerArray.push(result.user)
        } else if(result.type == this.CONST_PARTICIPANT_TYPE) {
          this.participantArray.push(result.user)
        }
      }
    })
  }

  getModules() {
    this.backendApiService.getApi('modules/type?type=note')
      .subscribe((response: any) => {
        this.modulesList = response?.data
      })
  }

  getStagesOfModule(id:any) {
    this.backendApiService.getApi('stages?module_id='+id)
      .subscribe((response: any) => {
        this.stages = response.data;
      })
  }

  selectedModule(event: any) {
    if (event){
    this.searchParams.type = event?.slug;
    this.searchParams.module_id = event?.id;
    this.searchParams.search = '';
    this.selectedStage = {};
    this.noteForm.get('module_id')?.setValue(event?.id);
    this.getStagesOfModule(event?.id);
    this.showSearchInput = true;
    }
    else{
      this.noteForm.get('reference_id')?.setValue('');
      this.noteForm.get('module_id')?.setValue('');
    }
    this.showSearchInput = true;
    this.selectedItem = '';
  }

  onItemSelect(event: any) {
    this.selectedItem = event;
    this.noteForm.get('reference_id')?.setValue(event?.id);
    this.showSearchInput = false;
  }

  updateSearchInputFlag() {
    this.showSearchInput = true;
  }

  getUsers(event: any){
    const search = event;
    this.backendApiService.getApi(`staff?search=${search}`, [], false)
      .subscribe((response: any) => {
        this.mentions = response.data;
      })
  }

getSelectedUser(event: any){
    const index = this.selectedMentionedUsers.findIndex((x: any) => x.id == event?.id);
    if(index == -1) {
      this.selectedMentionedUsers.push({user:event,type:'Users'});
    }
  }

  getFormData() {
    const form = this.noteForm.value;
    return {
      module_id: form.module_id,
      reference_id: form.reference_id,
      name: form.name,
      description: form.description,
      flag: form.flag == true ? 1 : 0,
      mentions:this.selectedMentionedUsers?.map((x: any) => this.mapDataOfArray(x)),
      attachment_ids: this.attachmentsToAdd,
      status:form.status
    }
  }

  mapDataOfArray(item: any) {
    return {id: item?.user.id,type:item?.type}
  }
}
