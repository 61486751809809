<ng-container *ngIf="!selectedItem">
  <mat-form-field class="search-mat-form-field">
    <input matInput placeholder="Search " color="warn" (blur)="onBlurSelectionInput()" (focus)="onFocusSelectionInput()" (keydown.enter)="$event.preventDefault()"[(ngModel)]="selectedSearchResult" [matAutocomplete]="auto"
           [formControl]="searchCtrl" placeholder="{{searchPlaceHolder}}">
    <button *ngIf="selectedSearchResult" matSuffix mat-icon-button aria-label="Clear" (click)="clearSelection()">
      <mat-icon>close</mat-icon>
    </button>
    <mat-autocomplete  #auto="matAutocomplete" (optionSelected)="onSelected()"
                       [displayWith]="displayWith">
      <mat-option *ngIf="isLoading" class="is-loading">Loading...</mat-option>
      <ng-container *ngIf="!isLoading">
        <mat-option *ngFor="let item of filteredResult" [value]="item">{{item[labelKey]}} </mat-option>
      </ng-container>
    </mat-autocomplete>
  </mat-form-field>
</ng-container>

<br>
<ng-container >
  <ng-container *ngIf="selectedItem">
    <div class="cross-icon" [style.background-color]="selectedItemBackGroundColor">
      <span class="search-text"> {{this.selectedItem[labelKey] ?? '--'}}</span>
      <span class="cross" (click)="clearSelection()">&#10005;</span>
    </div>
  </ng-container>
</ng-container>
