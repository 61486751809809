import {throwError as observableThrowError,  Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { tap, catchError } from 'rxjs/operators';
import {JsonService} from "../../../services/core/json.service";
import {authApiResources} from "../auth.constants";

@Injectable({
  providedIn: 'root'
})
export class AuthDataService {
  private baseURL:any;



  constructor(
    private http: HttpClient,
    private jsonService: JsonService,
  ) {
    this.baseURL = authApiResources;
  }

  onLogin(model: any): Observable<any> {
    const endPoint = `${this.baseURL.login}`;
    return this.http.post<any>(endPoint, model)
                    .pipe(tap((data:any) => (this.jsonService.doStringify(data))) , catchError(this.errorHandlerMessage));
  }

  onLogout(model: any): Observable<any> {
    const endPoint = `${this.baseURL.logout}`;
    return this.http.post<any>(endPoint, model)
      .pipe(tap((data:any) => (this.jsonService.doStringify(data))) , catchError(this.errorHandlerMessage));
  }


  onForgotPassword(model: any): Observable<any> {
    const endPoint = `${this.baseURL.forgotPassword}`;
    return this.http.post<any>(endPoint, model)
    .pipe(tap((data:any) => (this.jsonService.doStringify(data))),
    catchError(this.errorHandlerMessage));
  }

  onResetPassword(model: any): Observable<any> {
    const endPoint = `${this.baseURL.resetPassword}`;
    return this.http.post<any>(endPoint, model)
    .pipe(tap((data:any) => (this.jsonService.doStringify(data))),
    catchError(this.errorHandlerMessage));
  }

  // get User Permissions
  getUserPermissions(): Observable<any> {
      const endPoint = `${this.baseURL.userPermissions}`;
     return this.http.get<any>(endPoint).pipe(
        tap(data => this.jsonService.doStringify(data)),
        catchError(this.errorHandlerMessage),
      );
    }

  getAuthUserDetail(): Observable<any> {
    const endPoint = `${this.baseURL.userUserDetail}`;
    return this.http.get<any>(endPoint).pipe(
      tap(data => this.jsonService.doStringify(data.data)),
      catchError(this.errorHandlerMessage),
    );
  }

  setAuthUserOfficeSetting(model: any): Observable<any> {
    const endPoint = `${this.baseURL.userOfficeSetting}`;
    return this.http.post<any>(endPoint, model)
      .pipe(tap((data:any) => (this.jsonService.doStringify(data))) , catchError(this.errorHandlerMessage));
  }


  errorHandler(error: HttpErrorResponse) {
    return observableThrowError(error.error || 'Server Error');
  }

  errorHandlerMessage(error: HttpErrorResponse) {
    return observableThrowError(error.error || 'Server Error');
  }
}
