import {Component, Input, OnInit, SimpleChanges} from '@angular/core';

@Component({
  selector: 'app-show-data-point-with-tooltip',
  templateUrl: './show-data-point-with-tooltip.component.html',
  styleUrls: ['./show-data-point-with-tooltip.component.scss']
})
export class ShowDataPointWithTooltipComponent implements OnInit {

  constructor() { }
  @Input()  items:any = '';
  @Input()  key:any = null;
  @Input()  spanClass:any = 'value';

  ngOnInit(): void {
    this.items = this.removeNullAndEmpty(this.items);
  }
  ngOnChanges(changes: SimpleChanges) {

  }
  scopeString(value: any[]): string {
    if (!Array.isArray(value)) return '';
    if (this.key){
      return value.slice(1).map(scope => ''+scope[this.key]).join('  |  ');
    }
    return value.slice(1).map(scope => ''+scope).join('  |  ');
  }
  getFirstRecord(){
    const items = this.items;
    const key = this.key;
    console.log('this.key', this.key);
    return items?.length ? (key ?  items[0][key] : items[0]) : '--';
  }
  removeNullAndEmpty(items: any[]): any[] {
    if (!Array.isArray(items)) {
      return [];
    }

    return items.filter(item => item !== null && item !== undefined && item !== '');
  }
}
