import { right } from "@popperjs/core";
import { EChartOption } from "echarts";
import { colorList, getRandomDarkColor } from "src/app/utils/utils.function";

export const defaultChartOptions: EChartOption  = {
  tooltip: {
    trigger: 'item'
  },
  legend: {
    show:false,
    top: '5%',
    left: 'center'
  },
  series: [
    {
      name: 'Access From',
      type: 'pie',
      radius: ['40%', '70%'],
      avoidLabelOverlap: false,
      label: {
        show: false,
        position: 'center'
      },
      emphasis: {
        label: {
          show: true,
          fontSize: 40,
          fontWeight: 'bold'
        }
      },
      labelLine: {
        show: false
      },
      data: []
    }
  ]
};


export const chartOptions: EChartOption = {
  tooltip: {
    show: true,
    trigger: 'item',
    confine: false,
    position: (point: any, params, dom, rect, size: any) => {
      const contentSize = (size as { contentSize: [number, number] }).contentSize;
      const x = point[0] + 10; // Add an offset for x-position

      // Determine the y position based on cursor position
      let y;
      if (point[1] - contentSize[1] - 10 < 0) {
        // If there’s not enough space above the cursor, position below it
        y = point[1] + 10; // Add a slight offset below the cursor
      } else {
        // Position above the cursor otherwise
        y = point[1] - contentSize[1] - 10; // Adjust for tooltip height
      }

      return [x, y]; // Return the new tooltip position
    },
  },
  legend: {
    show: false,
    top: '30%',
    left: 'left',
    orient: 'vertical',
    itemHeight: 5,
    itemWidth: 10,
  },
  grid: {
    height: '40%',
    width: '20%',
  },
  series: [
    {
      name: 'Access From',
      type: 'pie',
      radius: ['55%', '70%'],
      avoidLabelOverlap: false,
      label: {
        show: false, // Disable label by default
        position: 'center',
      },
      emphasis: {
        label: {
          show: true, // Show label on hover
          formatter: '{d}%', // Show percentage on hover
          fontWeight: 'bold',
          fontFamily: 'Roboto',
          fontSize: 12,
        },
        itemStyle: {
          shadowBlur: 10,
          shadowOffsetX: 0,
          shadowColor: 'rgba(0, 0, 0, 0.5)',
        },
      },
      itemStyle: {
        color: (params: any) => {
          if (params.data?.color) {
            return params.data?.color;
          }
          // Return undefined to allow ECharts to use the default color palette
          return  colorList[params.dataIndex];
        },
      },
      labelLine: {
        show: false,
      },
      data: [
        { value: 1048, name: 'Total Profit', id: 'profit' },
        { value: 735, name: 'Total Cost', id: 'cost' },
        { value: 580, name: 'Other' }, // Additional data
        { value: 484, name: 'Extra' }, // Additional data
      ],
      width: '100%',
      height: '100%',
    },
  ] as any,
  graphic: [] as object[], // Initialize as an array
  
};
