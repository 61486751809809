import {environment} from "../../../environments/environment";

function createUrl(actionName: string): string {
  return `${environment.apiBaseUrl}${environment.apiUrl}${environment.apiVersion}${actionName}`;
}

export const TicketApiResources = {
  list:  createUrl('ticket'),
  detail : createUrl('ticket'),
  customers : createUrl('ticket/customers'),
  vendors : createUrl('ticket/vendors'),
  subcontractors : createUrl('ticket/subcontractors')
};
