import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ProposalComponentsCommunicationService } from '../../sale/proposal/services/proposal-components-communication.service';
import { ChangeOrderDataService } from '../../sale/change-order/change-order.data.service';
import { BackendApiService } from '../../../../app/services/backend-api.service';
import { HelperService } from '../../../../app/services/helper.service';
import { ToasterService } from '../../../../app/services/core/toaster.service';

@Component({
  selector: 'app-pdf-display-option',
  templateUrl: './pdf-display-option.component.html',
  styleUrls: ['./pdf-display-option.component.scss'],
})
export class PDFDisplayOptionsComponent implements OnInit {
  @Input() modelId: string = '';
  @Input() model: any = '';
  @Input() statusChange: any = '';
  @Output() onChangeDisplayOptions = new EventEmitter<any>(true);
  // @ts-ignore
  statusSubscription: Subscription;
  public optionsForm: UntypedFormGroup = this.getForm();
  constructor(
    private proposalComponentsCommunicationService: ProposalComponentsCommunicationService,
    private changeOrderDataService: ChangeOrderDataService,
    public backendApiService: BackendApiService,
    private helperService: HelperService,
    public toasterService: ToasterService
  ) {}

  ngOnInit(): void {
    this.optionsForm
      .get('proposal_status')
      ?.setValue(this.statusChange);
    this.initFormValues();
  }
  initFormValues() {
    this.optionsForm = this.getForm(this.model);
    // (async () => {
    //   await this.helperService.delay(4000);
    //   this.onChangeDisplayOptionsFn('', false);
    // })();
  }

  getForm(data: any = '') {
    const displayOptions = data?.display_options;
    return new UntypedFormGroup({
      proposal_status: new UntypedFormControl(data.status?.id),
      displayOptions: new UntypedFormGroup({
        product: new UntypedFormControl(displayOptions?.product ? true : false),
        service: new UntypedFormControl(displayOptions?.service ? true : false),
        labour: new UntypedFormControl(displayOptions?.labour ? true : false),
        shipping: new UntypedFormControl(
          displayOptions?.shipping ? true : false
        ),
        unitSellingPrice: new UntypedFormControl(
          displayOptions?.unitSellingPrice ? true : false
        ),
        signature: new UntypedFormControl(
          displayOptions?.signature ? true : false
        ),
        totalPrice: new UntypedFormControl(
          displayOptions?.totalPrice ? true : false
        ),
        providedServices: new UntypedFormControl(
          displayOptions?.providedServices ? true : false
        ),
        tax: new UntypedFormControl(displayOptions?.tax ? true : false),
        qualifyingNotes: new UntypedFormControl(
          displayOptions?.qualifyingNotes ? true : false
        ),
        scopeNotes: new UntypedFormControl(
          displayOptions?.scopeNotes ? true : false
        ),


        approvedQuantity: new UntypedFormControl(
          displayOptions?.show_approved_quantity ? true : false
        ),
        rejectedQuantity: new UntypedFormControl(
          displayOptions?.show_rejected_quantity ? true : false
        ),
        productSpecificationSheet: new UntypedFormControl(
          displayOptions?.show_product_specification_sheet ? true : false
        ),
        productWarrantySheet: new UntypedFormControl(
          displayOptions?.show_product_warranty_sheet ? true : false
        ),
        serviceSpecificationSheet: new UntypedFormControl(
          displayOptions?.show_service_specification_sheet ? true : false
        ),
        serviceWarrantySheet: new UntypedFormControl(
          displayOptions?.show_service_warranty_sheet ? true : false
        ),
      }),
    });
  }




  onChangeDisplayOptionsFn(event: any = '', updateOnBackEnd = true) {
    const displayOptions = this.optionsForm
      .get('displayOptions')
      ?.getRawValue();
    this.proposalComponentsCommunicationService.setDisplayOptions(
      displayOptions
    );
    if (updateOnBackEnd) {
      this.updateDisplayOptions();
    }
  }

  updateDisplayOptions() {
    const data = this.optionsForm
      .get('displayOptions')
      ?.getRawValue();
    this.backendApiService.postApi('submittals/display-options/'+this.modelId,data, false)
      .subscribe((response: any) => {
        this.toasterService.showSuccessMessage(response.data?.message);
      })
  }
  getDormData(){
    const displayOptions = this.optionsForm
      .get('displayOptions')
      ?.getRawValue();
    return [
    ];

  }

  ngOnDestroy() {
    // prevent memory leak when component destroyed
    // @ts-ignore
    // this.statusSubscription.unsubscribe();
  }
}
